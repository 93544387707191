import axios from 'axios'
import { URLService } from './_url';

// const baseurl = import.meta.env.VITE_APP_BaseURL
// axios.defaults.baseURL = baseurl

export const CommonService = {
  // init({ message: message == "" ? "Error Occured, Please contact administrator" : message, color: 'success' })
  async getcommondata(url) {
    return await axios.get(url)
  },
  async getVendorPolicies() {
    let url = URLService.getvendorpolicies;
    return await axios.get(url)
  },
  async getrunaway(url) {
    return await axios.get(url)
  },
  async suggestservice(url, data) {
    return await axios.post(url, data)
  },
  async deleteCollection(url, data) {
    return await axios.post(url, data)
  },
  async getDeliveryoption(url,data) {
    return await axios.post(url, data)
  },
  async getpaymentlink(url){
    return await axios.get(url)
  },
  async getpaymentstatus(url){
    return await axios.get(url)
  },
  async getDeliveryPrices(url,data) {
    return await axios.post(url, data)
  },
  

}
