// stores/cityStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCityStore = defineStore('city', () => {
  const cities = ref([]);

  const storeCities = (_cities) => {
    cities.value = _cities;
  };

  const getCities = () => {
    return cities.value;
  };

  return {
    storeCities,
    getCities
  };
});
