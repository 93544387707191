<template>
  <div class="app">
    <div class="safe-area">
      <!-- Main Content -->
      <router-view />
    </div>
    <NavBar />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import NavBar from "./components/NavBar";
import { useCartStore } from "@/stores/cartStore";
import "intl-tel-input/build/css/intlTelInput.css";
import { useUserStore } from "./stores/userStore";
import { CommonService } from "./services/commonservice";
import { useVendorStore } from "./stores/vendorStore";

const cartStore = useCartStore();
const userStore = useUserStore();
const vendorStore = useVendorStore();
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
import { UserService } from "./services/userservice";
import { URLService } from "./services/_url";
import { CityService } from "./services/cityservice";

const setcollection = async () => {
  try {
    var url = URLService.getcollections.replace(
      "{userid}",
      userStore.user?.usercode
    );
    var response = await UserService.getcollections(url);
    if (response.status == 200) {
      if (response.data) {
        let a = {
          id: 0,
          name: "All Saved",
          image: "",
          usercode: userStore.user?.usercode,
          m_user_id: userStore.user?.id,
          data: [],
        };
        let isdefaultavailable = false;
        response.data.forEach((element) => {
          if (element.name == "Favourites") {
            isdefaultavailable = true;
          }
          let _a = JSON.parse(element.data);
          if (_a) a.data = a.data.concat(_a);
        });

        let finaldata = response.data;
        if (isdefaultavailable == false) {
          let b = {
            id: -1,
            name: "Favourites",
            image:
              "https://wardrobetheappimgtorage1.blob.core.windows.net/wardrobe-container/collections/25.jpg",
            usercode: userStore.user?.usercode,
            m_user_id: userStore.user?.id,
            data: [],
          };
          b.data = JSON.stringify(b.data);
          finaldata.push(b);
        }

        a.data = JSON.stringify(a.data);
        finaldata.push(a);

        localStorage.setItem("allcollection", JSON.stringify(a));
        localStorage.setItem("userCollection", JSON.stringify(finaldata));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  if (Capacitor.isNativePlatform()) {
    const info = await Device.getInfo();
    if (info.platform === "ios") {
      document.body.classList.add("ios-platform");
    } else {
      document.body.classList.add("non-ios-platform");
    }

    await Browser.clearCookies();
    await cartStore.fetchOrder();
    await userStore.loadUserFromStorage();
    setcollection();

    try {
      CommonService.getVendorPolicies().then((data) => {
        vendorStore.setpolicies(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  } else {
    const info = await Device.getInfo();
    if (info.platform === "ios") {
      document.body.classList.add("ios-platform");
    } else if (info.operatingSystem == "mac") {
      document.body.classList.add("ios-platform");
    } else {
      document.body.classList.add("non-ios-platform");
    }
    await cartStore.fetchOrder();
    await userStore.loadUserFromStorage();

    setcollection();

    try {
      CommonService.getVendorPolicies().then((data) => {
        vendorStore.setpolicies(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }


  CityService.storeCities();
});
</script>

<style scoped>
.iti {
  --iti-path-flags-1x: url("../node_modules/intl-tel-input/build/img/flags.webp");
  --iti-path-flags-2x: url("../node_modules/intl-tel-input/build/img/flags@2x.webp");
  --iti-path-globe-1x: url("../node_modules/intl-tel-input/build/img/globe.webp");
  --iti-path-globe-2x: url("../node_modules/intl-tel-input/build/img/globe@2x.webp");
}

.safe-area {
  height: 100%;
  /* padding-top: env(safe-area-inset-top, 20px);  */
  box-sizing: border-box;
}

.app {
  padding-top: env(safe-area-inset-top, 20px);
}

/* iOS specific fix for large safe-area inset */
@supports (-webkit-touch-callout: none) {
  .app {
    padding-top: calc(
      env(safe-area-inset-top) - 20px
    ); /* Reduce the padding for iOS */
  }
}
</style>
