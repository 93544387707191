<template>
  <div class="modal" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content ">
      <h4 class="mb-2 mt-0" style="text-align: center;">Add your location</h4>

      <div class="wrapper" style="overflow-y: scroll">
        <div class="form-floating mb-3">
          <input
            type="text"
            id="fromName"
            class="form-control"
            v-model="address.name"
            maxlength="30"
            placeholder="Name"
            required
          />
          <label for="fromName">Name</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            id="addressline1"
            class="form-control"
            v-model="address.address1"
            maxlength="30"
            placeholder="Address Line 1"
            required
          />
          <label for="addressline1" class="form-label">Address Line 1</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            id="addressline2"
            class="form-control"
            v-model="address.address2"
            placeholder="Address Line 2"
            maxlength="30"
            required
          />
          <label for="addressline2" class="form-label">Address Line 2</label>
        </div>

        <div
          class="autocomplete-container form-floating mb-3"
          style="position: relative"
        >
          <!-- Input Field -->
          <input
            type="text"
            v-model="searchTerm"
            @input="filterCities"
            @focus="showDropdown = true"
            @blur="closeDropdown"
            placeholder="Select a city"
            class="form-control"
            ref="inputElement"
          />

          <!-- Floating Label -->
          <label
            for="city"
            class="form-label"
            :class="{
              'float-label':
                searchTerm ,
            }"
          >
            City
          </label>

          <!-- Dropdown List -->
          <ul
            v-if="showDropdown && filteredCities.length"
            class="dropdown-list"
            :style="{ top: dropdownPosition }"
          >
            <li
              v-for="(city, index) in filteredCities"
              :key="index"
              @mousedown="onCityChange(city.city)"
              class="dropdown-item"
            >
              {{ city.city }} <span style="font-size: smaller; color: #bfbfbf; font-style: italic;">{{ city.dis }}</span>
            </li>
          </ul>
        </div>
        <!-- <div
          class="autocomplete-container form-floating mb-3"
          style="position: relative"
        >
          <input
            type="text"
            v-model="searchTerm"
            @input="filterCities"
            @focus="showDropdown = true"
            @blur="closeDropdown"
            placeholder="Select a city"
            class="form-control"
            ref="inputElement"
          />
          <ul
            v-if="showDropdown && filteredCities.length"
            class="dropdown-list mx-3"
            :style="{ top: dropdownPosition }"
          >
            <li
              v-for="(city, index) in filteredCities"
              :key="index"
              @mousedown="onCityChange(city.city)"
              class="dropdown-item"
            >
              {{ city.city }}
            </li>
          </ul>
          <label for="phone" class="form-label">City</label>
        </div> -->

        <div
          class="mb-3 d-flex align-items-center"
          style="display: none !important"
        >
          <label for="province" class="form-label">Province</label>
          <input
            type="text"
            class="form-control"
            id="province"
            disabled
            v-model="address.province"
          />
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="postalcode"
            placeholder="Postal Code"
            v-model="address.postalcode"
          />
          <label for="postalcode" class="form-label">Postal Code</label>
        </div>

        <div
          v-for="address in addressList"
          v-bind:key="address.address1"
          class="address mb-2 d-flex justify-content-between align-items-center p-2"
          style="padding: 10px 0"
          @click="selectAddress(address)"
        >
          <div>
            <span style="font-weight: 800">{{ address.name }}</span> <br />
            <span>
              {{ address.address1 }}, {{ address.address2 }},
              {{ address.city }} ({{ address.postalcode }})
            </span>
          </div>
          <div>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div class="modal-actions" v-if="!showSelection">
        <button @click="cancelFilters" style="color: black">Cancel</button>
        <button
          v-if="userStore.isLoggedIn()"
          @click="applyFilters"
          style="background-color: black !important; color: white"
        >
          Save
        </button>
        <button
          v-if="!userStore.isLoggedIn()"
          @click="applyFilters"
          style="background-color: black !important; color: white"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/userStore";
import { ref, defineProps, defineEmits, onMounted, watch, nextTick } from "vue";
// import { Device } from "@capacitor/device";

const userStore = useUserStore();

const filterCities = () => {
  if (searchTerm.value) {
    filteredCities.value = props.cities.filter((city) =>
      city.city.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  } else {
    filteredCities.value = [];
  }
};
onMounted(() => {});

const $emit = defineEmits(["saveaddress", "selectadddress"]);
const saveaddress = () => {
  var selCity = props.cities.filter((city) => {
    return city.city == searchTerm.value;
  });
  let district = selCity.length > 0 ? selCity[0].district : "";
  let city = selCity.length > 0 ? selCity[0].city : "";
  let province = selCity.length > 0 ? selCity[0].province : "";
  let _address = {
    name: address.value.name,
    address1: address.value.address1,
    address2: address.value.address2,
    address3: address.value.address3,
    city: city,
    province: province,
    postalcode: address.value.postalcode,
    district: district,
  };
  $emit("saveaddress", _address);
  address.value = {
    name: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    province: "",
    district: "",
    postalcode: "",
  };
};

const selectAddress = (_address) => {
  var selCity = props.cities.filter((city) => {
    return city.city == _address.city;
  });
  let district = selCity.length > 0 ? selCity[0].district : "";
  _address.district = district;
  $emit("selectadddress", _address);
};

// const AdressList = ref([
//   {
//     name: "Home",
//     address1: "98/A",
//     address2: "Ragama",
//     address3: "",
//     city: "Gampaha",
//     province: "Western",
//     postalcode: "52007",
//   },
//   {
//     name: "Office",
//     address1: "102V",
//     address2: "Asgiriya",
//     address3: "Gampaha",
//     city: "Gampaha",
//     province: "Western",
//     postalcode: "11050",
//   },
// ]);

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
  cities: {
    type: Array,
  },
  addressList: {
    type: Array,
  },
});

const searchTerm = ref("");
const filteredCities = ref([]);
const showDropdown = ref(false);

const onCityChange = (event) => {
  showDropdown.value = false;
  var selCity = props.cities.filter((city) => {
    return city.city == event;
  });
  searchTerm.value = event;
  address.value.province = selCity.length > 0 ? selCity[0].province : "";
  address.value.district = selCity.length > 0 ? selCity[0].district : "";
};

const closeDropdown = () => {
  setTimeout(() => (showDropdown.value = false), 100); // Delay to allow click selection
};

watch(showDropdown, (newVal) => {
  if (newVal) {
    adjustDropdownPosition();
  }
});

const adjustDropdownPosition = async () => {
  if (!inputElement.value) return;

  await nextTick(); // Wait for DOM updates
  const inputRect = inputElement.value.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const dropdownHeight = Math.min(150, filteredCities.value.length * 40); // Approximate dropdown height

  // Calculate available space
  const spaceBelow = viewportHeight - inputRect.bottom;
  const spaceAbove = inputRect.top;

  if (spaceBelow >= dropdownHeight) {
    dropdownPosition.value = `${inputRect.height + 35}px`; // Show below input
  } else if (spaceAbove >= dropdownHeight) {
    dropdownPosition.value = `-${dropdownHeight}px`; // Show above input
  } else if (spaceBelow > spaceAbove) {
    dropdownPosition.value = `${inputRect.height}px`; // Partial dropdown below
  } else {
    dropdownPosition.value = `-${dropdownHeight}px`; // Partial dropdown above
  }
};

const dropdownPosition = ref("100%"); // Default dropdown position below the input
const inputElement = ref(null);

const showSelection = ref(false);
const address = ref({
  name: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  province: "",
  district: "",
  postalcode: "",
});

const applyFilters = async () => {
  await saveaddress();
  props.closeModal();
};

const cancelFilters = () => {
  props.closeModal();
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 600px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

.filter-options {
  list-style: none;
  padding: 0;
}

.filter-options li {
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-options li:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  background-color: white !important;
}

.btn-apply {
  background-color: white;
  color: black;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}

.filter-options li.active {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}

.address {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  background-color: #eaeaea;
}

.dropdown-list {
  left: 0;
  right: 0;
  top: -100px;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  z-index: 2;
  transition: top 0.2s ease;
}

input:focus {
  scroll-margin-bottom: 200px; /* Adds spacing for dropdown visibility */
}
.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.wrapper {
  /* height: var(--app-height); */
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.form-floating {
  position: relative;
}

.form-floating input {
  height: auto;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 15px;
}

.form-floating label {
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 12px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  padding: 0 4px;
  z-index: 1;
}

.form-floating input:focus + label,
.form-floating input:not(:placeholder-shown) + label {
  top: 4px;
  left: 5px;
  font-size: 12px;
  color: #333;
}

.form-floating input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}
</style>
