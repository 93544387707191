
// let cities = {
//     'Western': {
//         'colombo': [
//             "Colombo 01 - Fort",
//             "Colombo 02 - Slave Island &amp; Union Place",
//             "Colombo 03 - Kollupitiya",
//             "Colombo 04 - Bambalapitiya",
//             "Colombo 05 - Havelock Town &amp; Kirulapona",
//             "Colombo 06 - Wellawatte &amp; Pamankada",
//             "Colombo 07 - Cinnamon Gardens",
//             "Colombo 08 - Borella",
//             "Colombo 09 - Dematagoda",
//             "Colombo 10 - Maradana",
//             "Colombo 11 - Pettah",
//             "Colombo 12 - Hultsdorf",
//             "Colombo 13 - Kotahena &amp; Kochchikade",
//             "Colombo 14 - Grandpass",
//             "Colombo 15 - Modera &amp; Mattakkuliya"
//         ],
//         'Colombo Greater': [
//             "Aggona",
//             "Angoda",
//             "Angulana",
//             "Athurugriya",
//             "Attidiya",
//             "Avissawella",
//             "Battaramulla",
//             "Beddagana",
//             "Bellanvila",
//             "Bokundara",
//             "Bope",
//             "Bopitiya",
//             "Boralesgamuwa",
//             "Dedigamuwa",
//             "Dehiwala",
//             "Delkanda",
//             "Egoda Uyana",
//             "Embuldeniya",
//             "Gangodawila",
//             "Godagama",
//             "Gothatuwa",
//             "Habarakada",
//             "Hanwella",
//             "Himbutana",
//             "Hiripitya",
//             "Hokandara",
//             "Homagama",
//             "Jambugasmulla",
//             "Kaduwela",
//             "Kahathuduwa",
//             "Kalubowila",
//             "Katubedda",
//             "Kawdana",
//             "Kesbewa",
//             "Kiriwattuduwa",
//             "Kohuwala",
//             "Kolonnawa",
//             "Kosgama",
//             "Koswatta",
//             "Kotikawatta",
//             "Kottawa",
//             "Kotte",
//             "Lunawa",
//             "Madapatha",
//             "Madiwela",
//             "Maharagama",
//             "Malabe",
//             "Mattegoda",
//             "Meegoda",
//             "Mirihana",
//             "Moratuwa",
//             "Mount Lavinia",
//             "Mullegama",
//             "Mulleriyawa",
//             "Mulleriyawa New Town",
//             "Napawela",
//             "Nawala",
//             "Nawinna",
//             "Nedimala",
//             "Nugegoda",
//             "Obesekarapura",
//             "Orugodawatta",
//             "Padukka",
//             "Pagoda",
//             "Paliyagoda",
//             "Pannipitiya",
//             "Pelawatta",
//             "Pepiliyana",
//             "Piliyandala",
//             "Pitipana Homagama",
//             "Polgasowita",
//             "Rajagiriya",
//             "Rathmalana",
//             "Ratmalana",
//             "Rattanapitiya",
//             "Sapugaskande",
//             "Sedawatte",
//             "Siddamulla",
//             "Sri Jayawardenapura Kotte",
//             "Talangama",
//             "Talawatugoda",
//             "Thalawathugoda",
//             "Udahamulla",
//             "Waga",
//             "Watareka",
//             "Welikada",
//             "Welivita",
//             "Wellampitiya",
//             "Werahera",
//             "Wijerama"
//         ],
//         'Gampaha':
//             [
//                 "Alawala",
//                 "Ambagaspitiya",
//                 "Ambepussa",
//                 "Amuhena",
//                 "Andiambalama",
//                 "Attanagalla",
//                 "Badalgama",
//                 "Bemmulla",
//                 "Biyagama",
//                 "Buthpitiya",
//                 "Danowita",
//                 "Debahera",
//                 "Dekatana",
//                 "Delgoda",
//                 "Demalagama",
//                 "Dewalapola",
//                 "Divulapitiya",
//                 "Divuldeniya",
//                 "Dompe",
//                 "Dunagaha",
//                 "Ekala",
//                 "Ellakkala",
//                 "Essella",
//                 "Gampaha Town",
//                 "Ganemulla",
//                 "Gonawala",
//                 "Heiyanthuduwa",
//                 "Hendala",
//                 "Henegama",
//                 "Ihala Madampella",
//                 "Ja-Ela",
//                 "Kadawatha",
//                 "Kalagedihena",
//                 "Kaleliya",
//                 "Kaluaggala",
//                 "Kandana",
//                 "Katana",
//                 "Katunayake",
//                 "Kelaniya",
//                 "Kimbulapitiya",
//                 "Kiribathgoda",
//                 "Kirindiwela",
//                 "Kitulwala",
//                 "Kotadeniyawa",
//                 "Kotugada",
//                 "Lunugama",
//                 "Mabodala",
//                 "Mabole",
//                 "Madelgamuwa",
//                 "Mahabage",
//                 "Makola",
//                 "Malwana",
//                 "Mawaramandiya",
//                 "Mellawagedara",
//                 "Minuwangoda",
//                 "Mirigama",
//                 "Mudungoda",
//                 "Naiwella",
//                 "Negombo",
//                 "Nikahetikanda",
//                 "Nittambuwa",
//                 "Pallewela",
//                 "Pamunugama",
//                 "Pamunuwatta",
//                 "Pasyala",
//                 "Peliyagoda",
//                 "Pugoda",
//                 "Radawadunna",
//                 "Raddolugama",
//                 "Ragama",
//                 "Ranala",
//                 "Rukmale",
//                 "Seeduwa",
//                 "Siyambalape",
//                 "Udugampola",
//                 "Urapola",
//                 "Uswetakeiyawa",
//                 "Vayangoda",
//                 "Veyangoda",
//                 "Walpita",
//                 "Walpola",
//                 "Wanaluwewa",
//                 "Wattala",
//                 "Weliweriya",
//                 "Weweldeniya",
//                 "Yakkala",
//                 "Yatiyana"
//             ],
//         'Kalutara': [
//             "Agalawatta",
//             "Alubomulla",
//             "Aluthgama",
//             "Arukgoda",
//             "Athwelthota",
//             "Aubomulla",
//             "Awiththawa",
//             "Baduraliya",
//             "Bandaragama",
//             "Bekkegama",
//             "Bellana",
//             "Beruwala",
//             "Bolossagama",
//             "Bombuwala",
//             "Boralugoda",
//             "Bulathsinhala",
//             "China Fort",
//             "Daluwatta",
//             "Dediyawala",
//             "Delduwa",
//             "Dharga Town",
//             "Dibbedda",
//             "Diggala",
//             "Diyalagoda",
//             "Dodamgoda",
//             "Dodangoda",
//             "Dombagoda",
//             "Egal Oya",
//             "Eluwila",
//             "Galpatha",
//             "Galthude",
//             "Gamagoda",
//             "Gonapola Junction",
//             "Gorakana",
//             "Goraka Pola",
//             "Govinna",
//             "Halkandawila",
//             "Haltota",
//             "Halwatura",
//             "Handapangoda",
//             "Hedigalla Colony",
//             "Hirana",
//             "Horana",
//             "Horawala",
//             "Horethuduwa",
//             "Ingiriya",
//             "Ittapana",
//             "Kalamulla",
//             "Kaludewala",
//             "Kalugala",
//             "Kalutara",
//             "Kalutara Town",
//             "Kaluwamodara",
//             "Kananwila",
//             "Katukurunda",
//             "Kehelwatta",
//             "Keselwatta",
//             "Kethhena",
//             "Kiriberiya",
//             "Kitulgoda",
//             "Koholana",
//             "Kuruppu Mulla",
//             "Maggona",
//             "Mahagama",
//             "Mahakalupahana",
//             "Malamulla",
//             "Malegoda",
//             "Maradana",
//             "Mathugama",
//             "Matugama",
//             "Meegahatenna",
//             "Meegama",
//             "Melegama",
//             "Millaniya",
//             "Millewa",
//             "Miwanapalana",
//             "Molkawa",
//             "Molligoda",
//             "Moragalla",
//             "Morontuduwa",
//             "Mun Hena",
//             "Nagas Handiya",
//             "Narthupana",
//             "Neboda",
//             "Omaththa",
//             "Owitigala",
//             "Paiyagala",
//             "Panadura",
//             "Pannila",
//             "Paragastota",
//             "Paragoda",
//             "Paraigama",
//             "Paraththa",
//             "Pibura",
//             "Pohaddaramulla",
//             "Pokunuwita",
//             "Polgampola",
//             "Poruwedanda",
//             "Pothuwila",
//             "Remunagoda",
//             "Serupita",
//             "Tebuwana",
//             "Thalpitiya",
//             "Thanthirimulla",
//             "Thebuwana",
//             "Thudugala",
//             "Uduwara",
//             "Wadduwa",
//             "Walallawita",
//             "Walana",
//             "Walathara",
//             "Waskaduwa",
//             "Wehada",
//             "Welipenna",
//             "Welmilla Junction",
//             "Weththewa",
//             "Wettumakade",
//             "Yagirala",
//             "Yatadolawatta",
//             "Yatawara Junction"
//         ]
//     },
//     'Central': {
//         'Kandy': [
//             "Akurana",
//             "Aladeniya",
//             "Alawatugoda",
//             "Aludeniya",
//             "Ambatenna",
//             "Ampitiya",
//             "Ankubura",
//             "Ankumbura",
//             "Aruppola",
//             "Atabage",
//             "Balana",
//             "Batugoda",
//             "Bawlana",
//             "Bopana",
//             "Danthure",
//             "Daulagala",
//             "Dedunupitiya",
//             "Deltota",
//             "Digana",
//             "Doluwa",
//             "Doragamuwa",
//             "Etulgama",
//             "Galaboda",
//             "Galagedara",
//             "Galaha",
//             "Galhinna",
//             "Gampola",
//             "Gelioya",
//             "Godamunna",
//             "Gonagantenna",
//             "Guhagoda",
//             "Gunnepana",
//             "Gurudeniya",
//             "Haguranketha",
//             "Halloluwa",
//             "Handaganawa",
//             "Handessa",
//             "Hanguranketha",
//             "Haragama",
//             "Harankahawa",
//             "Harispattuwa",
//             "Hasalaka",
//             "Hatharaliyadda",
//             "Hewaheta",
//             "Hindagala",
//             "Hondiyadeniya",
//             "Hunnasgiriya",
//             "Jambugahapitiya",
//             "Kadugannawa",
//             "Kahataliyadda",
//             "Kalugala",
//             "Kandy Town",
//             "Kapuliyadde",
//             "Karandagolla",
//             "Katugastota",
//             "Kengalla",
//             "Ketakumbura",
//             "Kiribathkumbura",
//             "Kolongoda",
//             "Kulugammana",
//             "Kumbukkandura",
//             "Kundasale",
//             "Leemagahakotuwa",
//             "Lewella",
//             "Lunuketiya Maditta",
//             "Madawala",
//             "Madugalla",
//             "Madulkele",
//             "Mahadoraliyadda",
//             "Mahaiyawa",
//             "Mahamedagama",
//             "Mailapitiya",
//             "Makuldeniya",
//             "Mandaram Nuwara",
//             "Marassana",
//             "Maturata",
//             "Mawatura",
//             "Mawilmada",
//             "Medamahanuwara",
//             "Medawala Harispattuwa",
//             "Menikdiwela",
//             "Menikhinne",
//             "Mimure",
//             "Minigamuwa",
//             "Minipe",
//             "Muruthalawa",
//             "Naranpanawa",
//             "Nattarampotha",
//             "Nawalapitiya",
//             "Nugaliyadda",
//             "Nugawela",
//             "Pallekele",
//             "Pallekotuwa",
//             "Panwilatenna",
//             "Paradeka",
//             "Pasbage",
//             "Pattitalawa",
//             "Peradeniya",
//             "Pilawala",
//             "Pilimthalawa",
//             "Poholiyadda",
//             "Polgolla",
//             "Poththapitiya",
//             "Pujapitiya",
//             "Pupuressa",
//             "Pussellawa",
//             "Rajawella",
//             "Rambukpitiya",
//             "Rangala",
//             "Rantembe",
//             "Rathukohodigala",
//             "Rikillagaskada",
//             "Sangarajapura",
//             "Senarathwela",
//             "Talatuoya",
//             "Teldeniya",
//             "Thalatuoya",
//             "Thawalanthenna",
//             "Thennekubura",
//             "Udahentenna",
//             "Udahingulwala",
//             "Uda Peradeniya",
//             "Udawatta",
//             "Ududumbara",
//             "Uduwa",
//             "Uduwahinna",
//             "Uduwela",
//             "Ulapane",
//             "Ulpothagama",
//             "Unuwinna",
//             "Velamboda",
//             "Wattappola",
//             "Wattegama",
//             "Weligalla",
//             "Weligampola",
//             "Wendaruwa",
//             "Werellagama",
//             "Wettawa",
//             "Wilanagama",
//             "Yahalatenna",
//             "Yatihalagala"
//         ],
//         'Matale': [
//             "Akuramboda",
//             "Alawatta",
//             "Ambana",
//             "Ataragallewa",
//             "Bambaragaswewa",
//             "Beligamuwa",
//             "Dambulla",
//             "Dankanda",
//             "Devagiriya",
//             "Dewahuwa",
//             "Dullewa",
//             "Dunkolawatta",
//             "Dunuwilapitiya",
//             "Elkaduwa",
//             "Erawula Junction",
//             "Etanawala",
//             "Galewela",
//             "Gammaduwa",
//             "Gangala Puwakpitiya",
//             "Handungamuwa",
//             "Hattota Amuna",
//             "Hettipola",
//             "Illukkumbura",
//             "Imbulgolla",
//             "Inamaluwa",
//             "Kaikawala",
//             "Kalundawa",
//             "Kandalama",
//             "Karagahinna",
//             "Katudeniya",
//             "Kavudupelella",
//             "Kibissa",
//             "Kiwula",
//             "Kongahawela",
//             "Laggala Pallegama",
//             "Leliambe",
//             "Lenadora",
//             "Madawala Ulpotha",
//             "Madipola",
//             "Mahawela",
//             "Mananwatta",
//             "Maraka",
//             "Matale",
//             "Matale Town",
//             "Melipitiya",
//             "Metihakka",
//             "Millawana",
//             "Muwandeniya",
//             "Nalanda",
//             "Na ula",
//             "Nugagolla",
//             "Opalgala",
//             "Ovilikanda",
//             "Palapathwela",
//             "Pallepola",
//             "Perakanatta",
//             "Pubbiliya",
//             "Ranamuregama",
//             "Rattota",
//             "Selagama",
//             "Sigiriya",
//             "Talagoda Junction",
//             "Talakiriyagama",
//             "Udasgiriya",
//             "Udatenna",
//             "Ukuwela",
//             "Wahacotte",
//             "Walawela",
//             "Wehigala",
//             "Welangahawatte",
//             "Wewalawewa",
//             "Wilgamuwa",
//             "Yatawatta"
//         ],
//         'Nuwara Eliya': [
//             "Adhikarigama",
//             "Agarapatana",
//             "Ambagamuwa Udabulathgama",
//             "Ambatalawa",
//             "Ambewela",
//             "Bambarakelle",
//             "Barawardhanaoya",
//             "Bogawantalawa",
//             "Bopattalawa",
//             "Dagampitiya",
//             "Dayagama Bazaar",
//             "Degampitiya",
//             "Dikoya",
//             "Doragala",
//             "Dunukedeniya",
//             "Ginigathena",
//             "Gonakele",
//             "Hakgala",
//             "Halgran Oya",
//             "Hangarapitiya",
//             "Hapugastalawa",
//             "Harangalagama",
//             "Harasbedda",
//             "Hatton",
//             "Hawa Eliya",
//             "Hedunuwewa",
//             "Hitigegama",
//             "Idamegama",
//             "Kalaganwatta",
//             "Kandapola",
//             "Katukitula",
//             "Keerthi Bandarapura",
//             "Kelanigama",
//             "Ketaboola",
//             "Kotagala",
//             "Kotmale",
//             "Kottellena",
//             "Kumbalgamuwa",
//             "Kumbukwela",
//             "Kurupanawela",
//             "Labookelle",
//             "Labukele",
//             "Laxapana",
//             "Lindula",
//             "Madulla",
//             "Magastota",
//             "Maldeniya",
//             "Maskeliya",
//             "Maswela",
//             "Meethalawa",
//             "Mipanawa",
//             "Mipilimana",
//             "Morahenagama",
//             "Munwatta",
//             "Nanuoya",
//             "Nawathispane",
//             "Nildandahinna",
//             "Norwood",
//             "Nuwara Eliya",
//             "Nuwara Eliya Town",
//             "Padiyapelella",
//             "Palena",
//             "Patana",
//             "Pitawala",
//             "Pundaluoya",
//             "Pussalamankada",
//             "Radella",
//             "Ragala",
//             "Ramboda",
//             "Rozella",
//             "Rupaha",
//             "Ruwaneliya",
//             "Sadathenna",
//             "Santhipura",
//             "Talawakele",
//             "Teripeha",
//             "Udamadura",
//             "Udapussallawa",
//             "Walapane",
//             "Watagoda",
//             "Watagoda Hanspattuwa",
//             "Watawala",
//             "Widulipura",
//             "Wijebahukanda"
//         ],
//     },
//     'Eastern': {
//         'Ampara': [
//             "Addalaichenai",
//             "Akkaraipattu",
//             "Ampara",
//             "Ampara Town",
//             "Bakmitiyawa",
//             "Dadayamtalawa",
//             "Damana",
//             "Deegawapiya",
//             "Dehiattakandiya",
//             "Digamadulla",
//             "Dorakumbura",
//             "Gonagolla",
//             "Hingurana",
//             "Hulannuge",
//             "Irakkamama",
//             "Kalmunai",
//             "Karativu",
//             "Koknahara",
//             "Kolamanthalawa",
//             "Komari",
//             "Lahugala",
//             "Mahaoya",
//             "Malwatta",
//             "Mangalagama",
//             "Marathamune",
//             "Mawanagama",
//             "Moragahapallama",
//             "Namaloya",
//             "Navithanveli",
//             "Nawamedagama",
//             "Nintavur",
//             "Oluvil",
//             "Padiyathalawa",
//             "Pahalalanda",
//             "Palamunai",
//             "Panama",
//             "Pannalagama",
//             "Periyaneelavanai",
//             "Pottuvil",
//             "Rajagalatenna",
//             "Sainthamaruthu",
//             "Sammanthurai",
//             "Serankada",
//             "Siripura",
//             "Siyambalawewa",
//             "Tempitiya",
//             "Thambiluvil",
//             "Tirukkovil",
//             "Uhana"
//         ],
//         'Batticaloa': [
//             "Batticaloa Town",
//             "Chenkaladi",
//             "Eravur",
//             "Kalkudah",
//             "Karadiyanaru",
//             "Kattankudy",
//             "Koddamunai",
//             "Mankemi",
//             "Puliyanthivu",
//             "Puthur",
//             "Vakarai"
//         ],
//         'Trincomalee': [
//             "Agbopura",
//             "Anna Nagar",
//             "Arunagiri Nagar",
//             "Buckmigama",
//             "Chinabay",
//             "Dehiwatte",
//             "Deva Nagar",
//             "Echchilampattai",
//             "Galmetiyawa",
//             "Gomarankadawala",
//             "Kaddaiparichchan",
//             "Kanniya",
//             "Kantale",
//             "Kavaddikudah",
//             "Kiliveddy",
//             "Kinniya",
//             "Kuchchaveli",
//             "Kumburupiddy",
//             "Kurinchakemy",
//             "Lankapatuna",
//             "Linganagar",
//             "Mahadivulwewa",
//             "Maharugiramam",
//             "Mallikativu",
//             "Matikali",
//             "Mawadichchenai",
//             "Mihindapura",
//             "Mullipothana",
//             "Murugapuri",
//             "Mutur",
//             "Neelapola",
//             "Nelsonpura",
//             "Nilaveli",
//             "Nithiyapuri",
//             "Orrs Hill",
//             "Palaiyoothu",
//             "Pankulam",
//             "Rottawewa",
//             "Sampaltivu",
//             "Sampur",
//             "Samudragama",
//             "Serunuwara",
//             "Seruwila",
//             "Sirajnagar",
//             "Somapura",
//             "Tampalakamam",
//             "Thirukadaloor",
//             "Tiriyayi",
//             "Toppur",
//             "Trincomalee Town",
//             "Uppaveli",
//             "Vellamanal",
//             "Wanela"
//         ]
//     },
//     'North Central': {
//         'Anuradhapura': [
//             "Andiyagala",
//             "Anuradhapura Town",
//             "Awukana",
//             "Dematawewa",
//             "Dunumadalawa",
//             "Elayapattuwa",
//             "Eppawala",
//             "Etaweeragollewa",
//             "Galenbindunuwewa",
//             "Galkadawala",
//             "Galkiriyagama",
//             "Galnewa",
//             "Gambirigaswewa",
//             "Gemunupura",
//             "Gonahaddenawa",
//             "Habarana",
//             "Halmillawetiya",
//             "Halmillewa",
//             "Hidogama",
//             "Horowpothana",
//             "Hurulunikawewa",
//             "Ihalagama",
//             "Ipalogama",
//             "Kahatagasdigiliya",
//             "Kahatagasdigliya",
//             "Kalaoya",
//             "Kalawedi Ulpotha",
//             "Karagahawewa",
//             "Kebithigollawa",
//             "Kekirawa",
//             "Kendewa",
//             "Kirigalwewa",
//             "Madatugama",
//             "Mahabulankulama",
//             "Maha Elagamuwa",
//             "Mahailluppallama",
//             "Mahawilachchiya",
//             "Mailagaswewa",
//             "Maneruwa",
//             "Maradankadawala",
//             "Medawachchiya",
//             "Meegodawewa",
//             "Megodawewa",
//             "Mihintale",
//             "Morakewa",
//             "Nachchaduwa",
//             "Nochchiyagama",
//             "Nuwaragam Palatha",
//             "Padavi Siripura",
//             "Padavi Siritissapura",
//             "Padaviya",
//             "Parakumpura",
//             "Parangiyawadiya",
//             "Parasangahawewa",
//             "Pemaduwa",
//             "Pulmoddai",
//             "Rajanganaya",
//             "Rambewa",
//             "Ranorawa",
//             "Saliyapura",
//             "Siyambalewa",
//             "Talawa",
//             "Tambuttegama",
//             "Telhiriyawa",
//             "Thalawa",
//             "Thambuttegama",
//             "Thanthirimale",
//             "Thirappane",
//             "Tittagonewa",
//             "Udunuwara Colony",
//             "Wahalkada",
//             "Welimuwapotana",
//             "Welioya Project"
//         ],
//         'Polonnaruwa': [
//             "Aluthwewa",
//             "Alutwewa",
//             "Aralangawila",
//             "Aselapura",
//             "Attanakadawala",
//             "Bakamuna",
//             "Dalukana",
//             "Damminna",
//             "Dewagala",
//             "Dimbulagala",
//             "Divulankadawala",
//             "Divuldamana",
//             "Diyabeduma",
//             "Diyasenpura",
//             "Elahera",
//             "Ellewewa",
//             "Galamuna",
//             "Galoya Junction",
//             "Giritale",
//             "Hansayapalama",
//             "Hingurakdamana",
//             "Hingurakgoda",
//             "Jayanthipura",
//             "Jayasiripura",
//             "Kalingaela",
//             "Kalukele Badanagala",
//             "Kashyapapura",
//             "Kawudulla",
//             "Kawuduluwewa",
//             "Kottapitiya",
//             "Kumaragama",
//             "Lakshauyana",
//             "Maduruoya",
//             "Maha Ambagaswewa",
//             "Mahatalakolawewa",
//             "Mahawela Sinhapura",
//             "Mampitiya",
//             "Manampitiya",
//             "Medirigiriya",
//             "Meegaswewa",
//             "Minneriya",
//             "Mutugala",
//             "Nawasenapura",
//             "Nelumwewa",
//             "Nuwaragala",
//             "Onegama",
//             "Orubendi Siyambalawa",
//             "Palugasdamana",
//             "Parakrama Samudraya",
//             "Pelatiyawa",
//             "Pimburattewa",
//             "Polonnaruwa Town",
//             "Pulastigama",
//             "Sevanapitiya",
//             "Sinhagama",
//             "Sungavila",
//             "Talpotha",
//             "Tamankaduwa",
//             "Tambala",
//             "Unagalavehera",
//             "Welikanda",
//             "Wijayabapura",
//             "Yodaela",
//             "Yudaganawa"
//         ]
//     },
//     'Northern': {
//         'Jaffna': [
//             "Chavakachcheri",
//             "Delft Island",
//             "Jaffna Town",
//             "Kankesanthurai",
//             "Karainagar",
//             "Kayts",
//             "Kokuvil",
//             "Mandaitivu",
//             "Maviddapuram",
//             "Nallur",
//             "Point Pedro",
//             "Puloly",
//             "Vadamaradchy",
//             "Valikamam East",
//             "Valikamam North",
//             "Valikamam South",
//             "Valikamam West",
//             "Valvettithurai"
//         ],
//         'Kilinochchi': [
//             "Aliyavalai",
//             "Kilinochchi",
//             "Parantan",
//             "Pooneryn"
//         ],
//         'Mannar': [
//             "Mannar",
//             "Pesalai",
//             "Thalaimannar"
//         ],
//         'Mulativu': [
//             "Mulativu Town"
//         ],
//         'Vavuniya': [
//             "Omanthai",
//             "Puliyankulam",
//             "Vavuniya Town"
//         ],
//     },
//     'North Western': {
//         'Kurunegala': [
//             "Alawwa",
//             "Ambanpola",
//             "Ataragalla",
//             "Awulegama",
//             "Balalla",
//             "Bamunukotuwa",
//             "Bandara Koswatta",
//             "Bogahamulla",
//             "Bopitiya",
//             "Bujjomuwa",
//             "Dambadeniya",
//             "Deegalla",
//             "Demataluwa",
//             "Diddeniya",
//             "Divullegoda",
//             "Dodangaslanda",
//             "Etungahakotuwa",
//             "Galgamuwa",
//             "Giriulla",
//             "Gokaralla",
//             "Halmillawewa",
//             "Heraliyawela",
//             "Hindagolla",
//             "Hiruwalpola",
//             "Horambawa",
//             "Hulogedara",
//             "Hulugalla",
//             "Ibbagamuwa",
//             "Ilukhena",
//             "Indulgodakanda",
//             "Inguruwatta",
//             "Iriyagolla",
//             "Ithanawatta",
//             "Kadigawa",
//             "Kahapathwala",
//             "Kalugamuwa",
//             "Kanadeniyawala",
//             "Kanattewewa",
//             "Katupota",
//             "Kekunagolla",
//             "Keppitiwalana",
//             "Kirimetiyawa",
//             "Kirindigalla",
//             "Kithalawa",
//             "Kobeigane",
//             "Kohilagedara",
//             "Konwewa",
//             "Kosdeniya",
//             "Kosgolla",
//             "Kotawehera",
//             "Kudagalagamuwa",
//             "Kudakathnoruwa",
//             "Kuliyapitiya",
//             "Kumbukgeta",
//             "Kumbukwewa",
//             "Kuratihena",
//             "Kurunegala Town",
//             "Labbala",
//             "Lbbagamuwa",
//             "Lonahettiya",
//             "Madahapola",
//             "Madakumburumulla",
//             "Maduragoda",
//             "Maeliya",
//             "Mahagalkadawala",
//             "Mahagirilla",
//             "Mahamukalanyaya",
//             "Mahananneriya",
//             "Maharachchimulla",
//             "Maho",
//             "Makulpotha",
//             "Makulwewa",
//             "Malagane",
//             "Malkaduwawa",
//             "Malpitiya",
//             "Mandapola",
//             "Maspotha",
//             "Mawathagama",
//             "Meegalawa",
//             "Meewellawa",
//             "Melsiripura",
//             "Metikumbura",
//             "Metiyagane",
//             "Minhettiya",
//             "Minuwangete",
//             "Mirihanegama",
//             "Moragane",
//             "Moragollagama",
//             "Munamaldeniya",
//             "Nabadewa",
//             "Nagollagama",
//             "Nagollagoda",
//             "Nakkawatta",
//             "Narammala",
//             "Narangoda",
//             "Nawatalwatta",
//             "Nelliya",
//             "Nikadalupotha",
//             "Nikaweratiya",
//             "Padeniya",
//             "Padiwela",
//             "Pahalagiribawa",
//             "Pahamune",
//             "Palukadawala",
//             "Panadaragama",
//             "Panagamuwa",
//             "Panaliya",
//             "Panliyadda",
//             "Pannala",
//             "Pansiyagama",
//             "Periyakadneluwa",
//             "Pihimbiya Ratmale",
//             "Pihimbuwa",
//             "Pilessa",
//             "Polgahawela",
//             "Polpitigama",
//             "Pothuhera",
//             "Puswelitenna",
//             "Ridigama",
//             "Sandalankawa",
//             "Sirisethagama",
//             "Siyambalagamuwa",
//             "Solewewa",
//             "Sunandapura",
//             "Talawattegedara",
//             "Tambutta",
//             "Thalahitimulla",
//             "Thalakolawewa",
//             "Thalwita",
//             "Thambagalla",
//             "Tharana Udawela",
//             "Thimbiriyawa",
//             "Thorayaya",
//             "Tisogama",
//             "Torayaya",
//             "Tuttiripitigama",
//             "Udubaddawa",
//             "Uhumiya",
//             "Usgala Siyabmalangamuwa",
//             "Wadakada",
//             "Wadumunnegedara",
//             "Wannilhalagama",
//             "Wannirasnayakapura",
//             "Warawewa",
//             "Wariyapola",
//             "Watuwatta",
//             "Welawa Junction",
//             "Welipennagahamulla",
//             "Wellagala",
//             "Wellarawa",
//             "Wellawa",
//             "Wennoruwa",
//             "Weuda",
//             "Wewagama",
//             "Yakwila"
//         ],
//         'Puttalam': [
//             "Adippala",
//             "Anamaduwa",
//             "Andigama",
//             "Angunawila",
//             "Bangadeniya",
//             "Baranankattuwa",
//             "Battuluoya",
//             "Bingiriya",
//             "Bujjampola",
//             "Chilaw",
//             "Dankotuwa",
//             "Dunkannawa",
//             "Eluwankulama",
//             "Ettale",
//             "Ihala Kottaramulla",
//             "Ihala Puliyankulama",
//             "Kakkapalliya",
//             "Kalpitiya",
//             "Karativponparappi",
//             "Karuwalagaswewa",
//             "Katuneriya",
//             "Kirimundalama",
//             "Kudawewa",
//             "Kumarakattuwa",
//             "Kuruketiyawa",
//             "Lihiriyagama",
//             "Lunuwila",
//             "Madampe",
//             "Madurankuliya",
//             "Mahauswewa",
//             "Marawila",
//             "Mundel",
//             "Muttibendivila",
//             "Nainamadama",
//             "Nalladarankattuwa",
//             "Nattandiya",
//             "Nawagattegama",
//             "Norachcholai",
//             "Palaviya",
//             "Pallama",
//             "Palliwasalturai",
//             "Pothuwatawana",
//             "Puttalam Town",
//             "Rajakadaluwa",
//             "Saliyawewa Junction",
//             "Tabbowa",
//             "Talawila Church",
//             "Toduwawa",
//             "Udappu",
//             "Udappuwa",
//             "Uridyawa",
//             "Uriyawa",
//             "Vanathawilluwa",
//             "Waikkal",
//             "Watugahamulla",
//             "Wennappuwa",
//             "Wilpotha",
//             "Yogiyana"
//         ]
//     },
//     'Sabaragamuwa': {
//         'Kegalle': [
//             "Alawatura",
//             "Algama",
//             "Aluthnuwara",
//             "Ambalakanda",
//             "Ambulugala",
//             "Amitirigala",
//             "Ampagala",
//             "Anhettigama",
//             "Aranayake",
//             "Aruggammana",
//             "Atale",
//             "Batuwita",
//             "Beligala",
//             "Berannawa",
//             "Bopitiya",
//             "Boralankada",
//             "Bossella",
//             "Bulathkohupitiya",
//             "Damunupola",
//             "Daraniyagala",
//             "Debathgama",
//             "Dedigama",
//             "Dedugala",
//             "Deewala Pallegama",
//             "Dehiowita",
//             "Deldeniya",
//             "Deloluwa",
//             "Deraniyagala",
//             "Dewalegama",
//             "Dewanagala",
//             "Dombemada",
//             "Dorawaka",
//             "Dunumala",
//             "Galapitamada",
//             "Galatara",
//             "Galigamuwa",
//             "Galpatha",
//             "Gantuna",
//             "Gonagala",
//             "Hakabellawaka",
//             "Hakahinna",
//             "Hakbellawaka",
//             "Hawadiwela",
//             "Helamada",
//             "Hemmatagama",
//             "Hettimulla",
//             "Hewadiwela",
//             "Hingula",
//             "Hinguralakanda",
//             "Hiriwadunna",
//             "Imbulana",
//             "Imbulgasdeniya",
//             "Kabagamuwa",
//             "Kannattota",
//             "Kegalle Town",
//             "Kehelpannala",
//             "Kithulgala",
//             "Kitulgala",
//             "Kondeniya",
//             "Kotiyakumbura",
//             "Lewangama",
//             "Mahapallegama",
//             "Maharangalla",
//             "Makehelwala",
//             "Malalpola",
//             "Maliboda",
//             "Malmaduwa",
//             "Mawanella",
//             "Migastenna",
//             "Miyanawita",
//             "Molagoda",
//             "Morontota",
//             "Nelumdeniya",
//             "Niyadurupola",
//             "Noori",
//             "Parape",
//             "Pattampitiya",
//             "Pitagaldeniya",
//             "Rambukkana",
//             "Ruwanwella",
//             "Seaforth Colony",
//             "Talgaspitiya",
//             "Teligama",
//             "Thatawella",
//             "Tholangamuwa",
//             "Thotawella",
//             "Tulhiriya",
//             "Tuntota",
//             "Udagaldeniya",
//             "Udapotha",
//             "Udumulla",
//             "Undugoda",
//             "Ussapitiya",
//             "Wahakula",
//             "Waharaka",
//             "Warakapola",
//             "Watura",
//             "Weeoya",
//             "Wegalla",
//             "Welihelatenna",
//             "Weragala",
//             "Yatagama",
//             "Yatapana",
//             "Yatiyantota",
//             "Yattogoda"
//         ],
//         'Ratnapura': [
//             "Akarella",
//             "Atakalanapnna",
//             "Ayagama",
//             "Balangoda",
//             "Batatota",
//             "Belihuloya",
//             "Bolthumbe",
//             "Bulutota",
//             "Dambuluwana",
//             "Dela",
//             "Delwala",
//             "Demuwatha",
//             "Dodampe",
//             "Doloswalakanda",
//             "Dumbara Manana",
//             "Eheliyagoda",
//             "Ekamuthugama",
//             "Elapatha",
//             "Ellagawa",
//             "Ellawala",
//             "Embilipitiya",
//             "Erepola",
//             "Gabbela",
//             "Gallella",
//             "Gangeyaya",
//             "Gawaragiriya",
//             "Getahetta",
//             "Gillimale",
//             "Godagampola",
//             "Godakawela",
//             "Gurubewilagama",
//             "Halpe",
//             "Halwinna",
//             "Handagiriya",
//             "Hapugastenna",
//             "Hatangala",
//             "Hatarabage",
//             "Hiramadagama",
//             "Ihalagalagama",
//             "Imbulpe",
//             "Ittakanda",
//             "Kahangama",
//             "Kahawatte",
//             "Kalawana",
//             "Kalthota",
//             "Kaltota",
//             "Karandana",
//             "Karangoda",
//             "Karawita",
//             "Kella Junction",
//             "Kiribbanwewa",
//             "Kiriella",
//             "Kolambageara",
//             "Kolombugama",
//             "Kolonna",
//             "Kudawa",
//             "Kuruwita",
//             "Madalagama",
//             "Madampe",
//             "Mahagamakoloniya",
//             "Mahawalatenna",
//             "Makandura Sabara",
//             "Matuwagalagama",
//             "Meddekanda",
//             "Minipura Dumbara",
//             "Mitipola",
//             "Morahela",
//             "Mulendiyawala",
//             "Nawalakanda",
//             "Nivithigala",
//             "Omalpe",
//             "Opanayaka",
//             "Padalangala",
//             "Pallebedda",
//             "Pambagolla",
//             "Panamura",
//             "Panawala",
//             "Parakaduwa",
//             "Pebotuwa",
//             "Pelmadulla",
//             "Pimbura",
//             "Pinnawala",
//             "Rajawaka",
//             "Rakwana",
//             "Ranwala",
//             "Rassagala",
//             "Ratna Hangamuwa",
//             "Ratnapura Town",
//             "Samanalawewa",
//             "Sevanagala",
//             "Sri Palabaddala",
//             "Sudagala",
//             "Teppanawa",
//             "Tunkama",
//             "Udakarawita",
//             "Udaniriella",
//             "Udawalawe",
//             "Ullinduwawa",
//             "Veddagala",
//             "Vijeriya",
//             "Waleboda",
//             "Watapotha",
//             "Waturawa",
//             "Weligepola",
//             "Welipathayaya",
//             "Wewelwatta",
//             "Wikiliya"
//         ],
//     },
//     'Southern': {
//         'Galle': [
//             "Agaliya",
//             "Ahangama",
//             "Ahungalla",
//             "Akmeemana",
//             "Akuressa",
//             "Aluthwala",
//             "Ambalangoda",
//             "Ampegama",
//             "Amugoda",
//             "Anangoda",
//             "Angulugaha",
//             "Ankokkawala",
//             "Atakohota",
//             "Avittawa",
//             "Baddegama",
//             "Balapitiya",
//             "Banagala",
//             "Batapola",
//             "Benthota",
//             "Boossa",
//             "Dikkumbura",
//             "Dodanduwa",
//             "Elipitiya",
//             "Ella Tanabaddegama",
//             "Elpitiya",
//             "Ethkandura",
//             "Galle Town",
//             "Gintota",
//             "Godahena",
//             "Gonagalpura",
//             "Habaraduwa",
//             "Haburugala",
//             "Halvitigala Colony",
//             "Hawpe",
//             "Hikkaduwa",
//             "Hiniduma",
//             "Hiyare",
//             "Ihalahewessa",
//             "Ihala Walpola",
//             "Imaduwa",
//             "Induruwa",
//             "Kahaduwa",
//             "Kahawa",
//             "Kananke Bazaar",
//             "Karagoda",
//             "Karandeniya",
//             "Ketandola",
//             "Koggala",
//             "Kosgoda",
//             "Kothalawala",
//             "Kottawagama",
//             "Kurundugahahethakma",
//             "Madakumburamulla",
//             "Magala North",
//             "Magala South",
//             "Magedara",
//             "Malamura",
//             "Malgalla Talangalla",
//             "Mapalagama",
//             "Mapalagama Central",
//             "Mattaka",
//             "Meda Keembiya",
//             "Meetiyagoda",
//             "Miriswatta",
//             "Nagoda",
//             "Nakiyadeniya",
//             "Nawadagala",
//             "Neluwa",
//             "Nindana",
//             "Opatha",
//             "Panangala",
//             "Pannimulla Panagoda",
//             "Parana Thanayamgoda",
//             "Pitigala",
//             "Pitigala - North",
//             "Porawagama",
//             "Rantotuvila",
//             "Talagampola",
//             "Tawalama",
//             "Thalgaswala",
//             "Udalamatta",
//             "Udugama",
//             "Unawatuna",
//             "Uragasmanhandiya",
//             "Wackwella",
//             "Walahanduwa",
//             "Wanchawela",
//             "Wanduramba",
//             "Warukandeniya",
//             "Weihena",
//             "Yakkalamulla",
//             "Yatalamatta"
//         ],
//         'Hambantota': [
//             "Ambalantota",
//             "Angunakolapalassa",
//             "Beliatta",
//             "Beragama",
//             "Bundala",
//             "Hambantota",
//             "Hambantota Town",
//             "Hungama",
//             "Ittademaliya",
//             "Julampitiya",
//             "Kirinda",
//             "Lunugamwehera",
//             "Magama",
//             "Middeniya",
//             "Ranna",
//             "Ridiyagama",
//             "Sooriyawewa",
//             "Tangalle",
//             "Tissamaharama",
//             "Walasmulla",
//             "Weeraketiya",
//             "Weerawila"
//         ],
//         'Matara': [
//             "Akuressa",
//             "Alapaladeniya",
//             "Aparekka",
//             "Aturaliya",
//             "Bengamuwa",
//             "Beralapanathara",
//             "Bopagoda",
//             "Dampahala",
//             "Deegala Lenama",
//             "Deiyandara",
//             "Dellawa",
//             "Denagama",
//             "Denipitiya",
//             "Deniyaya",
//             "Derangala",
//             "Devinuwara",
//             "Devundara",
//             "Dikwella",
//             "Diyagaha",
//             "Diyalape",
//             "Gandara",
//             "Godagama",
//             "Godapitiya",
//             "Gomila Mawarala",
//             "Hakmana",
//             "Handugala",
//             "Hithetiya",
//             "Horapawita",
//             "Kalubowitiyana",
//             "Kamburugamuwa",
//             "Kamburupitiya",
//             "Karagoda Uyangoda",
//             "Karaputugala",
//             "Karatota",
//             "Kekanadura",
//             "Kiriweldola",
//             "Kiriwelkele",
//             "Kolawenigma",
//             "Kotapola",
//             "Kottegoda",
//             "Lankagama",
//             "Makandura",
//             "Maliduwa",
//             "Malimboda",
//             "Maramba",
//             "Matara",
//             "Matara Town",
//             "Mediripitiya",
//             "Miella",
//             "Mirissa",
//             "Modara",
//             "Moragala Kirillapone",
//             "Morawaka",
//             "Mulatiyana Junction",
//             "Nadugala",
//             "Naimana",
//             "Narawelpita",
//             "Nawimana",
//             "Nupe",
//             "Pahala Millawa",
//             "Palatuwa",
//             "Pamburana",
//             "Paragala",
//             "Parapamulla",
//             "Pasgoda",
//             "Penetiyana",
//             "Pitabeddara",
//             "Pothdeniya",
//             "Puhulwella",
//             "Radawela",
//             "Ransegoda",
//             "Ratmale",
//             "Rotumba",
//             "Siyambalagoda",
//             "Sultanagoda",
//             "Talaramba",
//             "Thelijjawila",
//             "Thihagoda",
//             "Thudawa",
//             "Urubokka",
//             "Urugamuwa",
//             "Urumutta",
//             "Uyanwatta",
//             "Viharahena",
//             "Walakanda",
//             "Walasgala",
//             "Walgama",
//             "Wallasmulla",
//             "Waralla",
//             "Weligama",
//             "Welihinda",
//             "Wilpita",
//             "Yatiyana"
//         ]
//     },
//     'Uva': {
//         'Badulla': [
//             "Akkarasiyaya",
//             "Aluketiyawa",
//             "Aluththaramma",
//             "Aluttaramma",
//             "Ambadandegama",
//             "Ambagahawatte",
//             "Ambagasdowa",
//             "Amunumulla",
//             "Arawa",
//             "Arawakumbura",
//             "Arawatta",
//             "Atakiriya",
//             "Badulla Town",
//             "Baduluoya",
//             "Ballaketuwa",
//             "Bambarapana",
//             "Bandarawela",
//             "Beramada",
//             "Bibilegama",
//             "Bogahakumbura",
//             "Boragas",
//             "Boralanda",
//             "Bowela",
//             "Dambana",
//             "Demodara",
//             "Diganatenna",
//             "Dikkapitiya",
//             "Dimbulana",
//             "Divulapelessa",
//             "Diyathalawa",
//             "Dulgolla",
//             "Egodawela",
//             "Ella",
//             "Ettempitiya",
//             "Gadunna",
//             "Galauda",
//             "Galedanda",
//             "Galporuyaya",
//             "Gamewela",
//             "Gawarawela",
//             "Girandurukotte",
//             "Godunna",
//             "Guruthalawa",
//             "Haldummulla",
//             "Hali",
//             "Hali-ela",
//             "Hangunnawa",
//             "Haputale",
//             "Hawanakumbura",
//             "Hebarawa",
//             "Heeloya",
//             "Helahalpe",
//             "Helapupula",
//             "Hewanakumbura",
//             "Hingurukaduwa",
//             "Hopton",
//             "Idalgashinna",
//             "Jangulla",
//             "Kabillawela",
//             "Kahataruppa",
//             "Kalubululanda",
//             "Kalugahakandura",
//             "Kalupahana",
//             "Kandaketiya",
//             "Kandegedara",
//             "Kandepuhulpola",
//             "Kebillawela",
//             "Kendagolla",
//             "Keppetipola",
//             "Keselpotha",
//             "Ketawatta",
//             "Kiriwanagama",
//             "Koslanda",
//             "Kotamuduna",
//             "Kuruwitenna",
//             "Kuttiyagolla",
//             "Landewela",
//             "Liyanagahawela",
//             "Lunugala",
//             "Lunuwatta",
//             "Madulsima",
//             "Mahiyangana",
//             "Mahiyanganaya",
//             "Makulella",
//             "Malgoda",
//             "Maliyadda",
//             "Mapakadawewa",
//             "Maspanna",
//             "Maussagolla",
//             "Medawelagama",
//             "Medawela Udukinda",
//             "Meegahakivula",
//             "Metigahatenna",
//             "Mirahawatta",
//             "Miriyabedda",
//             "Miyanakandura",
//             "Namunukula",
//             "Narangala",
//             "Nelumgama",
//             "Nikapotha",
//             "Nugatalawa",
//             "Ohiya",
//             "Pahalarathkinda",
//             "Pallekiruwa",
//             "Passara",
//             "Pathanewatta",
//             "Pattiyagedara",
//             "Pelagahatenna",
//             "Perawella",
//             "Pitamaruwa",
//             "Pitapola",
//             "Poonagala",
//             "Puhulpola",
//             "Ratkarawwa",
//             "Rideemaliyadda",
//             "Rilpola",
//             "Silmiyapura",
//             "Sirimalgoda",
//             "Sorabora Colony",
//             "Soragune",
//             "Soranathota",
//             "Spring Valley",
//             "Taldena",
//             "Tennepanguwa",
//             "Timbirigaspitiya",
//             "Uduhawara",
//             "Uraniya",
//             "Uva Deegalla",
//             "Uva Karandagolla",
//             "Uva Mawelagama",
//             "Uvaparanagama",
//             "Uva Tenna",
//             "Uva Tissapura",
//             "Uva Uduwara",
//             "Welimada",
//             "Wewatta",
//             "Wineethagama",
//             "Yalagamuwa",
//             "Yalwela"
//         ],
//         'Monaragala': [
//             "Angunakolawewa",
//             "Ayiwela",
//             "Badalkumbura",
//             "Baduluwela",
//             "Bakinigahawela",
//             "Balaharuwa",
//             "Bibile",
//             "Buddama",
//             "Buttala",
//             "Dambagalla",
//             "Diyakobala",
//             "Dombagahawela",
//             "Ekamutugama",
//             "Ekiriyankumbura",
//             "Ethimalewewa",
//             "Ettiliwewa",
//             "Galabedda",
//             "Hambegamuwa",
//             "Hulandawa",
//             "Inginiyagala",
//             "Kandaudapanguwa",
//             "Kandawinna",
//             "Kataragama",
//             "Kiriibbanwewa",
//             "Kotagama",
//             "Kotawehera Mankada",
//             "Kotiyagala",
//             "Kudaoya",
//             "Kumbukkana",
//             "Mahagama Colony",
//             "Marawa",
//             "Mariarawa",
//             "Medagana",
//             "Monaragala",
//             "Monaragala Town",
//             "Moretuwegama",
//             "Nakkala",
//             "Nannapurawa",
//             "Nelliyadda",
//             "Nilgala",
//             "Obbegoda",
//             "Okkampitiya",
//             "Pangura",
//             "Pitakumbura",
//             "Randeniya",
//             "Ruwalwela",
//             "Sella Kataragama",
//             "Sewanagala",
//             "Siyambalagane",
//             "Siyambalanduwa",
//             "Suriara",
//             "Tanamalila",
//             "Tanamalwila",
//             "Uva Gangodagama",
//             "Uva Kudaoya",
//             "Uva Pelwatta",
//             "Warunagama",
//             "Wedikumbura",
//             "Weherayaya Handapanagala",
//             "Wellawaya",
//             "Wilaoya"
//         ],
//     }
// }
// let provinces = ['Western', 'Central', 'Eastern', 'North Central', 'Northern', 'North Western', 'Sabaragamuwa', 'Southern', 'Uva']



// BODA BODA CITIES
let cities = {
    "Western": {
        "COLOMBO": [
            "Colombo 01",
            "Colombo 02",
            "Colombo 03",
            "Colombo 04",
            "Colombo 05",
            "Colombo 06",
            "Colombo 07",
            "Colombo 08",
            "Colombo 09",
            "Colombo 10",
            "Colombo 11",
            "Colombo 12",
            "Colombo 13",
            "Colombo 14",
            "Colombo 15",
            "Akarawita",
            "Athurugiriya",
            "Avissawella",
            "Batawala",
            "Battaramulla",
            "Batugampola",
            "Bope",
            "Boralesgamuwa",
            "Dedigamuwa",
            "Dehiwala",
            "Deltara",
            "Habarakada",
            "Handapangoda",
            "Hanwella",
            "Hewainna",
            "Hiripitya",
            "Hokandara",
            "Homagama",
            "Horagala",
            "Kaduwela",
            "Kahawala",
            "Kalatuwawa",
            "Kiriwattuduwa",
            "Kolonnawa",
            "Kosgama",
            "Madapatha",
            "Maharagama",
            "Malabe",
            "Meegoda",
            "Moratuwa",
            "Mount Lavinia",
            "Mullegama",
            "Mulleriyawa New Town",
            "Mutwal",
            "Napawela",
            "Nugegoda",
            "Padukka",
            "Pannipitiya",
            "Piliyandala",
            "Pitipana Homagama",
            "Polgasowita",
            "Puwakpitiya",
            "Ranala",
            "Ratmalana",
            "Siddamulla",
            "Sri Jayawardenapura",
            "Talawatugoda",
            "Tummodara",
            "Waga",
            "Watareka"
        ],
        "Gampaha": [
            "Akaragama",
            "Alawala",
            "Ambagaspitiya",
            "Ambepussa",
            "Andiambalama",
            "Attanagalla",
            "Badalgama",
            "Banduragoda",
            "Batuwatta",
            "Bemmulla",
            "Biyagama",
            "Biyagama IPZ",
            "Bokalagama",
            "Bollete (WP)",
            "Bopagama",
            "Buthpitiya",
            "Dagonna",
            "Danowita",
            "Debahera",
            "Dekatana",
            "Delgoda",
            "Delwagura",
            "Demalagama",
            "Demanhandiya",
            "Dewalapola",
            "Divulapitiya",
            "Divuldeniya",
            "Dompe",
            "Dunagaha",
            "Ekala",
            "Ellakkala",
            "Essella",
            "Gampaha",
            "Ganemulla",
            "GonawalaWP",
            "Heiyanthuduwa",
            "Henegama",
            "Hinatiyana Madawala",
            "Hiswella",
            "Horampella",
            "Hunumulla",
            "Ihala Madampella",
            "Imbulgoda",
            "Ja-Ela",
            "Kadawatha",
            "Kahatowita",
            "Kalagedihena",
            "Kaleliya",
            "Kaluaggala",
            "Kandana",
            "Kapugoda",
            "Katana",
            "Katunayake",
            "Katunayake Air Force Camp",
            "Katunayake(FTZ)",
            "Katuwellegama",
            "Kelaniya",
            "Kimbulapitiya",
            "Kirindiwela",
            "Kitalawalana",
            "Kitulwala",
            "Kochchikade",
            "Kotadeniyawa",
            "Kotugoda",
            "Kumbaloluwa",
            "Loluwagoda",
            "Lunugama",
            "Mabodale",
            "Madelgamuwa",
            "Makewita",
            "Makola",
            "Malwana",
            "Mandawala",
            "Marandagahamula",
            "Mellawagedara",
            "Minuwangoda",
            "Mirigama",
            "Mithirigala",
            "Muddaragama",
            "Mudungoda",
            "Naranwala",
            "Nawana",
            "Nedungamuwa",
            "Negombo",
            "Nikahetikanda",
            "Nittambuwa",
            "Niwandama",
            "Pallewela",
            "Pamunugama",
            "Pamunuwatta",
            "Pasyala",
            "Peliyagoda",
            "Pepiliyawala",
            "Pethiyagoda",
            "Polpithimukulana",
            "Pugoda",
            "Radawadunna",
            "Radawana",
            "Raddolugama",
            "Ragama",
            "Ruggahawila",
            "Rukmale",
            "Seeduwa",
            "Siyambalape",
            "Talahena",
            "Thimbirigaskatuwa",
            "Tittapattara",
            "Udathuthiripitiya",
            "Udugampola",
            "Uggalboda",
            "Urapola",
            "Uswetakeiyawa",
            "Veyangoda",
            "Walgammulla",
            "Walpita",
            "Walpola (WP)",
            "Wanaluwewa",
            "Wathurugama",
            "Watinapaha",
            "Wattala",
            "Weboda",
            "Wegowwa",
            "Weliveriya",
            "Weweldeniya",
            "Yakkala",
            "Yatiyana(WP)"
        ],
        "Kalutara": [
            "Agalawatta",
            "Alubomulla",
            "Alutgama",
            "Anguruwatota",
            "Baduraliya",
            "Bandaragama",
            "Bellana",
            "Beruwala",
            "Bolossagama",
            "Bombuwala",
            "Boralugoda",
            "Bulathsinhala",
            "Danawala Thiniyawala",
            "Delmella",
            "Dharga Town",
            "Diwalakada",
            "Dodangoda",
            "Dombagoda",
            "Galpatha",
            "Gamagoda",
            "Gonapola Junction",
            "Govinna",
            "Gurulubadda",
            "Halkandawila",
            "Haltota",
            "Halwala",
            "Halwatura",
            "Hedigalla Colony",
            "Horana",
            "lngiriya",
            "Ittapana",
            "Kalawila Kiranthidiya",
            "Kalutara",
            "Kananwila",
            "Kandanagama",
            "Kehelwatta",
            "Kelinkanda",
            "Kitulgoda",
            "Koholana",
            "Kuda Uduwa",
            "Maggona",
            "Mahagama",
            "Mahakalupahana",
            "Matugama",
            "Meegahatenna",
            "Meegama",
            "Millaniya",
            "Millewa",
            "Miwanapalana",
            "Molkawa",
            "Morapitiya",
            "Morontuduwa",
            "Nawattuduwa",
            "Neboda",
            "Padagoda",
            "Pahalahewessa",
            "Paiyagala",
            "Panadura",
            "Pannila",
            "Paragastota",
            "Paragoda",
            "Paraigama",
            "Pelanda",
            "Pelawatta",
            "Pokunuwita",
            "Polgampola",
            "Poruwedanda",
            "Remunagoda",
            "Tebuwana",
            "Uduwara",
            "Utumgama",
            "Veyangalla",
            "Wadduwa",
            "Walagedara",
            "Walallawita",
            "Waskaduwa",
            "Welipenna",
            "Welmilla Junction",
            "Yagirala",
            "Yatadolawatta",
            "Yatawara Junction",
            "Agalawatta",
            "Alubomulla",
            "Alutgama",
            "Anguruwatota",
            "Baduraliya",
            "Bandaragama",
            "Bellana",
            "Beruwala",
            "Bolossagama",
            "Bombuwala",
            "Boralugoda",
            "Bulathsinhala",
            "Danawala Thiniyawala",
            "Delmella",
            "Dharga Town",
            "Diwalakada",
            "Dodangoda",
            "Dombagoda",
            "Galpatha",
            "Gamagoda",
            "Gonapola Junction",
            "Govinna",
            "Gurulubadda",
            "Halkandawila",
            "Haltota",
            "Halwala",
            "Halwatura",
            "Hedigalla Colony",
            "Horana",
            "lngiriya",
            "Ittapana",
            "Kalawila Kiranthidiya",
            "Kalutara",
            "Kananwila",
            "Kandanagama",
            "Kehelwatta",
            "Kelinkanda",
            "Kitulgoda",
            "Koholana",
            "Kuda Uduwa",
            "Maggona",
            "Mahagama",
            "Mahakalupahana",
            "Matugama",
            "Meegahatenna",
            "Meegama",
            "Millaniya",
            "Millewa",
            "Miwanapalana",
            "Molkawa",
            "Morapitiya",
            "Morontuduwa",
            "Nawattuduwa",
            "Neboda",
            "Padagoda",
            "Pahalahewessa",
            "Paiyagala",
            "Panadura",
            "Pannila",
            "Paragastota",
            "Paragoda",
            "Paraigama",
            "Pelanda",
            "Pelawatta",
            "Pokunuwita",
            "Polgampola",
            "Poruwedanda",
            "Remunagoda",
            "Tebuwana",
            "Uduwara",
            "Utumgama",
            "Veyangalla",
            "Wadduwa",
            "Walagedara",
            "Walallawita",
            "Waskaduwa",
            "Welipenna",
            "Welmilla Junction",
            "Yagirala",
            "Yatadolawatta",
            "Yatawara Junction"
        ]
    },
    "Central": {
        "Kandy": [
            "Akurana",
            "Alawatugoda",
            "Aludeniya",
            "Ambagahapelessa",
            "Ambatenna",
            "Ampitiya",
            "Ankumbura",
            "Atabage",
            "Balana",
            "Bambaragahaela",
            "Barawardhana Oya",
            "Batagolladeniya",
            "Batugoda",
            "Batumulla",
            "Bawlana",
            "Bopana",
            "Danture",
            "Dedunupitiya",
            "Dekinda",
            "Deltota",
            "Dolapihilla",
            "Dolosbage",
            "Doluwa",
            "Doragamuwa",
            "Dunuwila",
            "Ekiriya",
            "Elamulla",
            "Etulgama",
            "Galaboda",
            "Galagedara",
            "Galaha",
            "Galhinna",
            "Gallellagama",
            "Gampola",
            "Gelioya",
            "Godamunna",
            "Gomagoda",
            "Gonagantenna",
            "Gonawalapatana",
            "Gunnepana",
            "Gurudeniya",
            "Halloluwa",
            "Handaganawa",
            "Handawalapitiya",
            "Handessa",
            "Hanguranketha",
            "Harankahawa",
            "Hasalaka",
            "Hataraliyadda",
            "Hewaheta",
            "Hindagala",
            "Hondiyadeniya",
            "Hunnasgiriya",
            "lhala Kobbekaduwa",
            "lllagolla",
            "Jambugahapitiya",
            "Kadugannawa",
            "Kahataliyadda",
            "Kalugala",
            "Kandy",
            "Kapuliyadde",
            "Karandagolla",
            "Katugastota",
            "Kengalla",
            "Ketakumbura",
            "Ketawala Leula",
            "Kiribathkumbura",
            "Kobonila",
            "Kolabissa",
            "Kolongoda",
            "Kulugammana",
            "Kumbukkandura",
            "Kumburegama",
            "Kundasale",
            "Leemagahakotuwa",
            "Lunuketiya Maditta",
            "Madawala Bazaar",
            "Madugalla",
            "Madulkele",
            "Mahadoraliyadda",
            "Mahamedagama",
            "Mailapitiya",
            "Makkanigama",
            "Makuldeniya",
            "Mandaram Nuwara",
            "Mapakanda",
            "Marassana",
            "Marymount Colony",
            "Maturata",
            "Mawatura",
            "Medamahanuwara",
            "Medawala Harispattuwa",
            "Meetalawa",
            "Megoda Kalugamuwa",
            "Menikdiwela",
            "Menikhinna",
            "Mimure",
            "Minigamuwa",
            "Minipe",
            "Murutalawa",
            "Muruthagahamulla",
            "Naranpanawa",
            "Nattarampotha",
            "Nawalapitiya",
            "Nillambe",
            "Nugaliyadda",
            "Nugawela",
            "Pallebowala",
            "Pallekotuwa",
            "Panvila",
            "Panwilatenna",
            "Paradeka",
            "Pasbage",
            "Pattitalawa",
            "Pattiya Watta",
            "Peradeniya",
            "Pilawala",
            "Pilimatalawa",
            "Poholiyadda",
            "Polgolla",
            "Pujapitiya",
            "Pupuressa",
            "Pussellawa",
            "Putuhapuwa",
            "Rajawella",
            "Rambukpitiya",
            "Rambukwella",
            "Rangala",
            "Rantembe",
            "Rathukohodigala",
            "Rikillagaskada",
            "Sangarajapura",
            "Senarathwela",
            "Talatuoya",
            "Tawalantenna",
            "Teldeniya",
            "Tennekumbura",
            "Uda Peradeniya",
            "Udahentenna",
            "Udahingulwala",
            "Udatalawinna",
            "Udawatta",
            "Udispattuwa",
            "Ududumbara",
            "Uduwa",
            "Uduwahinna",
            "Uduwela",
            "Ulapane",
            "Ulpothagama",
            "Unuwinna",
            "Velamboda",
            "Wattappola",
            "Wattegama",
            "Watagoda Harispattuwa",
            "Weligalla",
            "Weligampola",
            "Wendaruwa",
            "Weragantota",
            "Werapitya",
            "Werellagama",
            "Wettawa",
            "Wilanagama",
            "Yahalatenna",
            "Yatihalagala",
            "Adhikarigama",
            "Alagalla",
            "Amunugama",
            "Augustawatte",
            "Bahirawakande",
            "Belungala",
            "Bokkawala",
            "Bowalawatta",
            "Bulukohotenna",
            "Dambawela",
            "Dampola",
            "Dangolla",
            "Deegahawathura",
            "Dehianga",
            "Dehideniya - Madige",
            "Dunukumbura",
            "Galabawa",
            "Galagedera Madige",
            "Galagedera Naranwela",
            "Gohagoda",
            "Handabowa",
            "Hantana",
            "Idamegama",
            "Ihalamulla",
            "Imbulatenna",
            "Kanda Alupotakada",
            "Kandanhenagama",
            "Kandy General Hospital",
            "Kasawatta",
            "Katukele",
            "Kinigama Narawatta",
            "Kobbegala",
            "Konakalagala",
            "Kurugoda",
            "Lamasuriyagama",
            "Leula Dodanwala",
            "Maha Damunupola",
            "Mahaiyawa",
            "Mahaiyawa Gramaya",
            "Mahapattapola",
            "Malawanahinna",
            "Maoyawatta",
            "Marathugoda",
            "Mavilmada",
            "Meegahahena",
            "Muruthagahamula",
            "Niyangoda",
            "Owissa",
            "Palena",
            "Pathamailapitiya",
            "Primrose Garden",
            "Pussalamankada",
            "Senkadagala Bowala",
            "Sirimalwatte",
            "Suduhumpola",
            "Suriyagoda",
            "Telambugahawatte",
            "Udagama Lunuwella",
            "Udahenepola",
            "Udaweliketiya",
            "Ududeniya",
            "Unantenna"
        ],
        "Matale": [
            "Akuramboda",
            "Alwatta",
            "Ambana",
            "Ataragallewa",
            "Bambaragaswewa",
            "Beligamuwa",
            "Dambulla",
            "Dankanda",
            "Devagiriya",
            "Dewahuwa",
            "Dullewa",
            "Dunkolawatta",
            "Dunuwilapitiya",
            "Elkaduwa",
            "Erawula Junction",
            "Etanawala",
            "Galewela",
            "Gammaduwa",
            "Gangala Puwakpitiya",
            "Handungamuwa",
            "Hattota Amuna",
            "lllukkumbura",
            "Imbulgolla",
            "Inamaluwa",
            "Kaikawala",
            "Kalundawa",
            "Kandalama",
            "Karagahinna",
            "Katudeniya",
            "Kavudupelella",
            "Kibissa",
            "Kiwula",
            "Kongahawela",
            "Laggala Pallegama",
            "Leliambe",
            "Lenadora",
            "Madawala Ulpotha",
            "Madipola",
            "Mahawela",
            "Mananwatta",
            "Maraka",
            "Matale",
            "Melipitiya",
            "Metihakka",
            "Millawana",
            "Muwandeniya",
            "Nalanda",
            "Naula",
            "Nugagolla",
            "Opalgala",
            "Ovilikanda",
            "Palapathwela",
            "Pallepola",
            "Perakanatta",
            "Pubbiliya",
            "Ranamuregama",
            "Rattota",
            "Selagama",
            "Sigiriya",
            "Talagoda Junction",
            "Talakiriyagama",
            "Udasgiriya",
            "Udatenna",
            "Ukuwela",
            "Wahacotte",
            "Walawela",
            "Wehigala",
            "Welangahawatte",
            "Wewalawewa",
            "Wilgamuwa",
            "Yatawatta"
        ],
        "Nuwaraeliya": [
            "Agarapathana",
            "Ambagamuwa Udabulathgama",
            "Ambatalawa",
            "Ambewela",
            "Bogawantalawa",
            "Bopattalawa",
            "Dagampitiya",
            "Dayagama Bazaar",
            "Dikoya",
            "Doragala",
            "Dunukedeniya",
            "Ginigathena",
            "Gonakele",
            "Haggala",
            "Halgranoya",
            "Hangarapitiya",
            "Hapugastalawa",
            "Harangalagama",
            "Harasbedda",
            "Hatton",
            "Hedunuwewa",
            "Hitigegama",
            "Kalaganwatta",
            "Kandapola",
            "Katukitula",
            "Keerthi Bandarapura",
            "Kelanigama",
            "Ketaboola",
            "Kotagala",
            "Kotmale",
            "Kottellena",
            "Kumbalgamuwa",
            "Kumbukwela",
            "Kurupanawela",
            "Labukele",
            "Laxapana",
            "Lindula",
            "Madulla",
            "Maldeniya",
            "Maskeliya",
            "Maswela",
            "Mipanawa",
            "Mipilimana",
            "Morahenagama",
            "Munwatta",
            "Nanuoya",
            "Nawathispane",
            "Nayapana Janapadaya",
            "Nildandahinna",
            "Nissanka Uyana",
            "Norwood",
            "Nuwara Eliya",
            "Padiyapelella",
            "Patana",
            "Pitawala",
            "Pundaluoya",
            "Ramboda",
            "Rozella",
            "Rupaha",
            "Ruwaneliya",
            "Santhipura",
            "Talawakele",
            "Teripeha",
            "Udamadura",
            "Udapussallawa",
            "Walapane",
            "Watagoda",
            "Watawala",
            "Widulipura",
            "Wijebahukanda"
        ]
    },
    "Eastern": {
        "Batticaloa": [
            "Ampilanthurai",
            "Araipattai",
            "Ayithiyamalai",
            "Bakiella",
            "Batticaloa",
            "Cheddipalayam",
            "Chenkaladi",
            "Eravur",
            "Kalkudah",
            "Kallar",
            "Kaluwanchikudi",
            "Kaluwankemy",
            "Kannankudah",
            "Karadiyanaru",
            "Kathiraveli",
            "Kattankudi",
            "Kiran",
            "Kirankulam",
            "Koddaikallar",
            "Kokkaddichcholai",
            "Kurukkalmadam",
            "Mandur",
            "Mankemi",
            "Miravodai",
            "Murakottanchanai",
            "Navagirinagar",
            "Navatkadu",
            "Oddamavadi",
            "Panichankemi",
            "Pankudavely",
            "Periyaporativu",
            "Periyapullumalai",
            "Pillaiyaradi",
            "Punanai",
            "Thannamunai",
            "Thettativu",
            "Thikkodai",
            "Thirupalugamam",
            "Thuraineelavanai",
            "Unnichchai",
            "Vakaneri",
            "Vakarai",
            "Valaichenai",
            "Vantharumoolai",
            "Vellavely"
        ],
        "Mullativu": [
            "Puthukudiyiruppu"
        ],
        "Trincomalee": [
            "Agbopura",
            "Buckmigama",
            "Chinabay",
            "Dehiwatte",
            "Echchilampattai",
            "Galmetiyawa",
            "Gomarankadawala",
            "Kaddaiparichchan",
            "Kanniya",
            "Kantalai",
            "Kantalai Sugar Factory",
            "Kiliveddy",
            "Kinniya",
            "Kuchchaveli",
            "Kumburupiddy",
            "Kurinchakemy",
            "Lankapatuna",
            "Mahadivulwewa",
            "Maharugiramam",
            "Mallikativu",
            "Mawadichenai",
            "Mullipothana",
            "Mutur",
            "Neelapola",
            "Nilaveli",
            "Pankulam",
            "Rottawewa",
            "Sampaltivu",
            "Sampur",
            "Serunuwara",
            "Seruwila",
            "Sirajnagar",
            "Somapura",
            "Tampalakamam",
            "Tiriyayi",
            "Toppur",
            "Trincomalee",
            "Vellamanal",
            "Wanela"
        ],
        "Ampara": [
            "Addalaichenai",
            "Akkaraipattu",
            "Ampara",
            "Bakmitiyawa",
            "Central Camp",
            "Dadayamtalawa",
            "Damana",
            "Damanewela",
            "Deegawapiya",
            "Dehiattakandiya",
            "Devalahinda",
            "Digamadulla Weeragoda",
            "Dorakumbura",
            "Galapitagala",
            "Gonagolla",
            "Hingurana",
            "Hulannuge",
            "lmkkamam",
            "Kalmunai",
            "Kannakipuram",
            "Karativu",
            "Kekirihena",
            "Koknahara",
            "Kolamanthalawa",
            "Komari",
            "Lahugala",
            "Madawalalanda",
            "Mahanagapura",
            "Mahaoya",
            "Malwatta",
            "Mangalagama",
            "Marathamune",
            "Mawanagama",
            "Moragahapallama",
            "Namaloya",
            "Navithanveli",
            "Nawamedagama",
            "Nintavur",
            "Oluvil",
            "Padiyatalawa",
            "Pahalalanda",
            "Palamunai",
            "Panama",
            "Pannalagama",
            "Paragahakele",
            "Periyaneelavanai",
            "Polwaga Janapadaya",
            "Pottuvil",
            "Rajagalatenna",
            "Sainthamaruthu",
            "Samanthurai",
            "Serankada",
            "Siripura",
            "Siyambalawewa",
            "Tempitiya",
            "Thambiluvil",
            "Tirukovil",
            "Uhana",
            "Wadinagala",
            "Wanagamuwa",
            "Werunketagoda"
        ]
    },
    "Northern": {
        "Jaffna": [
            "Allaipiddi",
            "Allaveddi",
            "Alvai",
            "Anaicoddai",
            "Analaitivu",
            "Atchuveli",
            "Chankanai",
            "Chavakachcheri",
            "Chullipuram",
            "Chundikuli",
            "Chunnakam",
            "Delft",
            "DelftWest",
            "Eluvaitivu",
            "Erlalai",
            "Jaffna",
            "Kaitadi",
            "Kankesanthurai",
            "Karainagar",
            "Karaveddi",
            "Kayts",
            "Kodikamam",
            "Kokuvil",
            "Kondavil",
            "Kopay",
            "Kudatanai",
            "llavalai",
            "Mallakam",
            "Manipay",
            "Mathagal",
            "Meesalai",
            "Mirusuvil",
            "Nagar Kovil",
            "Nainathivu",
            "Neervely",
            "Pandaterippu",
            "Point Pedro",
            "Pungudutivu",
            "Putur",
            "Sandilipay",
            "Sithankemy",
            "Tellippallai",
            "Thondamanaru",
            "Urumpirai",
            "Vaddukoddai",
            "Valvettithurai",
            "Vannarponnai",
            "Varany",
            "Vasavilan",
            "Velanai"
        ],
        "Kilinochchi": [
            "Kilinochchi"
        ],
        "Mannar": [
            "Adampan",
            "Arippu",
            "Athimottai",
            "Chilavathurai",
            "Erukkalampiddy",
            "llluppaikadavai",
            "Madhu Church",
            "Madhu Road",
            "Mannar",
            "Marichchi Kaddi",
            "Murunkan",
            "Nanattan",
            "P.P.Potkemy",
            "Palampiddy",
            "Periyakunchikulam",
            "Periyamadhu",
            "Pesalai",
            "Talaimannar",
            "Temple",
            "Tharapuram",
            "Thiruketheeswaram Temple",
            "Uyilankulam",
            "Vaddakandal",
            "Vankalai",
            "Vellan Kulam",
            "Vidataltivu"
        ],
        "Mullativu": [
            "Mullativu",
            "Alampil",
            "Karuppaddamurippu",
            "Kokkilai",
            "Kokkuthuoduvai",
            "Mankulam",
            "Mullivaikkal",
            "Mulliyawalai",
            "Muthauyan Kaddakulam",
            "Naddan Kandal",
            "Odduchudan",
            "Puthuvedduvan",
            "Thunukkai",
            "Udayarkaddu",
            "Vavunakkulam",
            "Visvamadukulam",
            "Yogapuram"
        ],
        "Vavuniya": [
            "Vavuniya",
            "Akkarayankulam",
            "Cheddikulam",
            "Chemamadukkulam",
            "Elephant Pass",
            "Eluthumadduval",
            "Iranai lluppaikulam",
            "Iranaitiv",
            "Kanagarayankulam",
            "Kanavil",
            "Kandavalai",
            "Kavutharimunai",
            "Kilinochchi",
            "lyakachchi",
            "Mahakachchakodiya",
            "Mamaduwa",
            "Maraiyadithakulam",
            "Mulliyan",
            "Murasumoddai",
            "Nedunkemy",
            "Neriyakulam",
            "Omanthai",
            "Palamoddai",
            "Pallai",
            "Pallavarayankaddu",
            "Pampaimadu",
            "Paranthan",
            "Pavaikulam",
            "Periyathambanai",
            "Periyaulukkulam",
            "Purakari Nallur",
            "Ramanathapuram",
            "Sasthrikulankulam",
            "Sivapuram",
            "Skanthapuram",
            "Thalayadi",
            "Tharmapuram",
            "Uruthirapuram",
            "Vaddakachchi",
            "Vannerikkulam",
            "Varikkuttiyoor",
            "Veravil",
            "Vinayagapuram"
        ]
    },
    "North Central": {
        "Anuradhapura": [
            "Andiyagala",
            "Angamuwa",
            "Anuradhapura",
            "Awukana",
            "Bogahawewa",
            "Dematawewa",
            "Dunumadalawa",
            "Dutuwewa",
            "Elayapattuwa",
            "Eppawala",
            "Etawatunuwewa",
            "Etaweeragollewa",
            "Galadivulwewa",
            "Galenbindunuwewa",
            "Galkadawala",
            "Galkiriyagama",
            "Galkulama",
            "Galnewa",
            "Gambirigaswewa",
            "Ganewalpola",
            "Gemunupura",
            "Getalawa",
            "Gnanikulama",
            "Gonahaddenawa",
            "Habarana",
            "Halmillawa Dambulla",
            "Halmillawetiya",
            "Hidogama",
            "Horawpatana",
            "Horiwila",
            "Hurigaswewa",
            "Hurulunikawewa",
            "lhala Halmillewa",
            "lhalagama",
            "lpologama",
            "Kagama",
            "Kahatagasdigiliya",
            "Kahatagollewa",
            "Kalakarambewa",
            "Kalankuttiya",
            "Kalaoya",
            "Kalawedi Ulpotha",
            "Kallanchiya",
            "Kapugallawa",
            "Karagahawewa",
            "Katiyawa",
            "Kebithigollewa",
            "Kekirawa",
            "Kendewa",
            "Kiralogama",
            "Kirigalwewa",
            "Kitulhitiyawa",
            "Kurundankulama",
            "Labunoruwa",
            "Madatugama",
            "Maha Elagamuwa",
            "Mahabulankulama",
            "Mahailluppallama",
            "Mahakanadarawa",
            "Mahapothana",
            "Mahasenpura",
            "Mahawilachchiya",
            "Mailagaswewa",
            "Malwanagama",
            "Maneruwa",
            "Maradankadawala",
            "Maradankalla",
            "Medawachchiya",
            "Megodawewa",
            "Mihintale",
            "Morakewa",
            "Mulkiriyawa",
            "Muriyakadawala",
            "Nachchaduwa",
            "Namalpura",
            "Negampaha",
            "Nochchiyagama",
            "Padavi Maithripura",
            "Padavi Parakramapura",
            "Padavi Sripura",
            "Padavi Sritissapura",
            "Padaviya",
            "Padikaramaduwa",
            "Pahala Halmillewa",
            "Pahala Maragahawe",
            "Pahalagama",
            "Palagala",
            "Palugaswewa",
            "Pandukabayapura",
            "Pandulagama",
            "Parakumpura",
            "Parangiyawadiya",
            "Parasangahawewa",
            "Pemaduwa",
            "Perimiyankulama",
            "Pihimbiyagolewa",
            "Pubbogama",
            "Pulmoddai",
            "Punewa",
            "Rajanganaya",
            "Rambewa",
            "Rampathwila",
            "Ranorawa",
            "Rathmalgahawewa",
            "Saliyapura",
            "Seeppukulama",
            "Senapura",
            "Sivalakulama",
            "Siyambalewa",
            "Sravasthipura",
            "Talawa",
            "Tambuttegama",
            "Tammennawa",
            "Tantirimale",
            "Telhiriyawa",
            "Tirappane",
            "Tittagonewa",
            "Udunuwara Colony",
            "Upuldeniya",
            "Uttimaduwa",
            "Viharapalugama",
            "Vijithapura",
            "Wahalkada",
            "Wahamalgollewa",
            "Walagambahuwa",
            "Walahaviddawewa",
            "Welimuwapotana",
            "Welioya Project"
        ],
        "Polonnaruwa": [
            "Alutwewa",
            "Aralaganwila",
            "Aselapura",
            "Attanakadawala",
            "Bakamuna",
            "Dalukana",
            "Damminna",
            "Dewagala",
            "Dimbulagala",
            "Divulankadawala",
            "Divuldamana",
            "Diyabeduma",
            "Diyasenpura",
            "Elahera",
            "Ellewewa",
            "Galamuna",
            "Galoya Junction",
            "Giritale",
            "Hansayapalama",
            "Hingurakdamana",
            "Hingurakgoda",
            "Jayanthipura",
            "Jayasiripura",
            "Kalingaela",
            "Kalukele Badanagala",
            "Kashyapapura",
            "Kawudulla",
            "Kawuduluwewa Stagell",
            "Kottapitiya",
            "Kumaragama",
            "Lakshauyana",
            "Maduruoya",
            "Maha Ambagaswewa",
            "Mahatalakolawewa",
            "Mahawela Sinhapura",
            "Mampitiya",
            "Medirigiriya",
            "Meegaswewa",
            "Minneriya",
            "Mutugala",
            "Nawasenapura",
            "Nelumwewa",
            "Nuwaragala",
            "Onegama",
            "Orubendi Siyambalawa",
            "Palugasdamana",
            "Parakramasamudraya",
            "Pelatiyawa",
            "Pimburattewa",
            "Polonnaruwa",
            "Pulastigama",
            "Sevanapitiya",
            "Sinhagama",
            "Sungavila",
            "Talpotha",
            "Tamankaduwa",
            "Tambala",
            "Unagalavehera",
            "Welikanda",
            "Wijayabapura",
            "Yodaela",
            "Yudaganawa"
        ]
    },
    "North Western": {
        "Kurunegala": [
            "Alahengama",
            "Alahitiyawa",
            "Alawatuwala",
            "Alawwa",
            "Ambakote",
            "Ambanpola",
            "Anhandiya",
            "Anukkane",
            "Aragoda",
            "Ataragalla",
            "Awulegama",
            "Balalla",
            "Bamunukotuwa",
            "Bandara Koswatta",
            "Bingiriya",
            "Bogamulla",
            "Bopitiya",
            "Boraluwewa",
            "Boyagane",
            "Bujjomuwa",
            "Buluwala",
            "Dambadeniya",
            "Daraluwa",
            "Deegalla",
            "Delwite",
            "Demataluwa",
            "Diddeniya",
            "Digannewa",
            "Divullegoda",
            "Dodangaslanda",
            "Doratiyawa",
            "Dummalasuriya",
            "Ehetuwewa",
            "Elibichchiya",
            "Embogama",
            "Etungahakotuwa",
            "Galgamuwa",
            "Gallewa",
            "Girathalana",
            "Giriulla",
            "Gokaralla",
            "Gonawila",
            "Halmillawewa",
            "Hengamuwa",
            "Hettipola",
            "Hilogama",
            "Hindagolla",
            "Hiriyala Lenawa",
            "Hiruwalpola",
            "Horambawa",
            "Hulogedara",
            "Hulugalla",
            "Hunupola",
            "lbbagamuwa",
            "Ihala Gomugomuwa",
            "lhala Kadigamuwa",
            "Ihala Katugampala",
            "llukhena",
            "Indulgodakanda",
            "Inguruwatta",
            "Iriyagolla",
            "Ithanawatta",
            "Kadigawa",
            "Kahapathwala",
            "Kalugamuwa",
            "Kanadeniyawala",
            "Kanattewewa",
            "Karagahagedara",
            "Karambe",
            "Katupota",
            "Kekunagolla",
            "Keppitiwalana",
            "Kimbulwanaoya",
            "Kirimetiyawa",
            "Kirindawa",
            "Kirindigalla",
            "Kithalawa",
            "Kobeigane",
            "Kohilagedara",
            "Konwewa",
            "Kosdeniya",
            "Kosgolla",
            "Kotawehera",
            "Kudagalgamuwa",
            "Kudakatnoruwa",
            "Kuliyapitiya",
            "Kumbukgeta",
            "Kumbukwewa",
            "Kuratihena",
            "Kurunegala",
            "Labbala",
            "Lonahettiya",
            "Madahapola",
            "Madakumburumulla",
            "Maduragoda",
            "Maeliya",
            "Magulagama",
            "Mahagalkadawala",
            "Mahagirilla",
            "Mahamukalanyaya",
            "Mahananneriya",
            "Maharachchimulla",
            "Maho",
            "Makulewa",
            "Makulpotha",
            "Makulwewa",
            "Malagane",
            "Mandapola",
            "Maspotha",
            "Mawathagama",
            "Medivawa",
            "Meegalawa",
            "Meetanwala",
            "Meewellawa",
            "Melsiripura",
            "Metikumbura",
            "Metiyagane",
            "Minhettiya",
            "Minuwangete",
            "Mirihanagama",
            "Monnekulama",
            "Moragane",
            "Moragollagama",
            "Morathiha",
            "Munamaldeniya",
            "Muruthenge",
            "Nabadewa",
            "Nagollagama",
            "Nagollagoda",
            "Nakkawatta",
            "Narammala",
            "Narangoda",
            "Nawatalwatta",
            "Nelliya",
            "Nikadalupotha",
            "Nikaweratiya",
            "Padeniya",
            "Padiwela",
            "Pahalagiribawa",
            "Pahamune",
            "Palukadawala",
            "Panadaragama",
            "Panagamuwa",
            "Panaliya",
            "Panliyadda",
            "Pannala",
            "Pansiyagama",
            "Periyakadneluwa",
            "Pihimbiya Ratmale",
            "Pihimbuwa",
            "Pilessa",
            "Polgahawela",
            "Polpitigama",
            "Pothuhera",
            "Puswelitenna",
            "Ridibendiella",
            "Ridigama",
            "Saliya Asokapura",
            "Sandalankawa",
            "Sirisetagama",
            "Siyambalangamuwa",
            "Solepura",
            "Solewewa",
            "Sunandapura",
            "Talawattegedara",
            "Tambutta",
            "Thalahitimulla",
            "Thalakolawewa",
            "Thalwita",
            "Thambagalla",
            "Tharana Udawela",
            "Thimbiriyawa",
            "Tisogama",
            "Torayaya",
            "Tuttiripitigama",
            "Udubaddawa",
            "Uhumiya",
            "Ulpotha Pallekele",
            "Usgala Siyabmalangamuwa",
            "Wadakada",
            "Wadumunnegedara",
            "Walakumburumulla",
            "Wannigama",
            "Wannikudawewa",
            "Wannilhalagama",
            "Wannirasnayakapura",
            "Warawewa",
            "Wariyapola",
            "Watuwatta",
            "Weerapokuna",
            "Welawa Juncton",
            "Welipennagahamulla",
            "Wellagala",
            "Wellarawa",
            "Wellawa",
            "Welpalla",
            "Wennoruwa",
            "Weuda",
            "Wewagama",
            "Yakwila",
            "Yatigaloluwa"
        ],
        "Puttalam": [
            "Adippala",
            "Ambakandawila",
            "Anamaduwa",
            "Andigama",
            "Angunawila",
            "Attawilluwa",
            "Bangadeniya",
            "Baranankattuwa",
            "Battuluoya",
            "Bujjampola",
            "Chilaw",
            "Dankotuwa",
            "Dunkannawa",
            "Eluwankulama",
            "Ettale",
            "Galmuruwa",
            "Ihala Kottaramulla",
            "Ihala Puliyankulama",
            "Ilippadeniya",
            "Inginimitiya",
            "Ismailpuram",
            "Kakkapalliya",
            "Kalladiya",
            "Kalpitiya",
            "Kandakuliya",
            "Karativponparappi",
            "Karawitagara",
            "Karuwalagaswewa",
            "Katuneriya",
            "Kirimundalama",
            "Koswatta",
            "Kottantivu",
            "Kottukachchiya",
            "Kudawewa",
            "Kumarakattuwa",
            "Kurinjanpitiya",
            "Kuruketiyawa",
            "Lihiriyagama",
            "Lunuwila",
            "Madampe",
            "Madurankuliya",
            "Mahakumbukkadawala",
            "Mahauswewa",
            "Mahawewa",
            "Mampuri",
            "Mangalaeliya",
            "Marawila",
            "Mudalakkuliya",
            "Mugunuwatawana",
            "Mukkutoduwawa",
            "Mundel",
            "Muttibendiwila",
            "Nainamadama",
            "Nalladarankattuwa",
            "Nattandiya",
            "Nawagattegama",
            "Norachcholai",
            "Palaviya",
            "Pallama",
            "Palliwasalturai",
            "Panirendawa",
            "Pothuwatawana",
            "Puttalam Cement Factory",
            "Puttalam",
            "Rajakadaluwa",
            "Saliyawewa Junction",
            "Serukele",
            "Sirambiadiya",
            "Siyambalagashene",
            "Tabbowa",
            "Talawila Church",
            "Toduwawa",
            "Udappuwa",
            "Uridyawa",
            "Vanathawilluwa",
            "Waikkal",
            "Watugahamulla",
            "Wennappuwa",
            "Wijeyakatupotha",
            "Wilpotha",
            "Yogiyana"
        ]
    },
    "Sabaragamuwa": {
        "Ratnapura": [
            "Akarella",
            "Atakalanpanna",
            "Ayagama",
            "Balangoda",
            "Batatota",
            "Belihuloya",
            "Bolthumbe",
            "Bomluwageaina",
            "Bulutota",
            "Dambuluwana",
            "Daugala",
            "Dela",
            "Delwala",
            "Demuwatha",
            "Dodampe",
            "Doloswalakanda",
            "Dumbara Manana",
            "Eheliyagoda",
            "Elapatha",
            "Ellagawa",
            "Ellaulla",
            "Ellawala",
            "Embilipitiya",
            "Eratna",
            "Erepola",
            "Gabbela",
            "Gallella",
            "Gangeyaya",
            "Gawaragiriya",
            "Getahetta",
            "Gillimale",
            "Godagampola",
            "Godakawela",
            "Gurubewilagama",
            "Halpe",
            "Halwinna",
            "Handagiriya",
            "Hapugastenna",
            "Hatangala",
            "Hatarabage",
            "Hidellana",
            "Hiramadagama",
            "Ihalagama",
            "lmbulpe",
            "Ittakanda",
            "Kahangama",
            "Kahawatta",
            "Kalawana",
            "Kaltota",
            "Karandana",
            "Karangoda",
            "Kella Junction",
            "Kiriella",
            "Kolambageara",
            "Kolombugama",
            "Kolonna",
            "Kudawa",
            "Kuruwita",
            "Lellopitiya",
            "Madalagama",
            "Mahawalatenna",
            "Makandura Sabara",
            "Malwala Junction",
            "Marapana",
            "Matuwagalagama",
            "Medagalatur",
            "Meddekanda",
            "Minipura Dumbara",
            "Mitipola",
            "Morahela",
            "Mulendiyawala",
            "Mulgama",
            "Nawalakanda",
            "NawinnaPinnakanda",
            "Niralagama",
            "Nivitigala",
            "Omalpe",
            "Opanayaka",
            "Padalangala",
            "Pallebedda",
            "Pambagolla",
            "Panamura",
            "Panapitiya",
            "Panapola",
            "Panawala",
            "Parakaduwa",
            "Pebotuwa",
            "Pelmadulla",
            "Pimbura",
            "Pinnawala",
            "Pothupitiya",
            "Rajawaka",
            "Rakwana",
            "Ranwala",
            "Rassagala",
            "Ratna Hangamuwa",
            "Ratnapura",
            "Samanalawewa",
            "Sri Palabaddala",
            "Sudagala",
            "Talakolahinna",
            "Tanjantenna",
            "Teppanawa",
            "Tunkama",
            "Udaha Hawupe",
            "Udakarawita",
            "Udaniriella",
            "Udawalawe",
            "Ullinduwawa",
            "Veddagala",
            "Vijeriya",
            "Waleboda",
            "Watapotha",
            "Waturawa",
            "Weligepola",
            "Welipathayaya",
            "Wewelwatta",
            "Wikiliya"
        ],
        "Kegalle": [
            "Alawatura",
            "Algama",
            "Alutnuwara",
            "Ambalakanda",
            "Ambulugala",
            "Amitirigala",
            "Ampagala",
            "Anhettigama",
            "Aranayaka",
            "Aruggammana",
            "Atale",
            "Batuwita",
            "Beligala(Sab)",
            "Berannawa",
            "Bopitiya (SAB)",
            "Boralankada",
            "Bossella",
            "Bulathkohupitiya",
            "Damunupola",
            "Debathgama",
            "Dedugala",
            "Deewala Pallegama",
            "Dehiowita",
            "Deldeniya",
            "Deloluwa",
            "Deraniyagala",
            "Dewalegama",
            "Dewanagala",
            "Dombemada",
            "Dorawaka",
            "Dunumala",
            "Galapitamada",
            "Galatara",
            "Galigamuwa Town",
            "Galpatha(Sab)",
            "Gantuna",
            "Gonagala",
            "Hakahinna",
            "Hakbellawaka",
            "Helamada",
            "Hemmatagama",
            "Hettimulla",
            "Hewadiwela",
            "Hingula",
            "Hinguralakanda",
            "Hiriwadunna",
            "Imbulana",
            "Imbulgasdeniya",
            "Kabagamuwa",
            "Kannattota",
            "Kegalle",
            "Kehelpannala",
            "Kitulgala",
            "Kondeniya",
            "Kotiyakumbura",
            "Lewangama",
            "Mahabage",
            "Mahapallegama",
            "Maharangalla",
            "Makehelwala",
            "Malalpola",
            "Maliboda",
            "Malmaduwa",
            "Mawanella",
            "Migastenna Sabara",
            "Miyanawita",
            "Molagoda",
            "Morontota",
            "Nelundeniya",
            "Niyadurupola",
            "Noori",
            "Parape",
            "Pattampitiya",
            "Pitagaldeniya",
            "Pothukoladeniya",
            "Rambukkana",
            "Ruwanwella",
            "Seaforth Colony",
            "Talgaspitiya",
            "Teligama",
            "Tholangamuwa",
            "Thotawella",
            "Tulhiriya",
            "Tuntota",
            "Udagaldeniya",
            "Udapotha",
            "Udumulla",
            "Undugoda",
            "Ussapitiya",
            "Wahakula",
            "Waharaka",
            "Warakapola",
            "Watura",
            "Weeoya",
            "Wegalla",
            "Welihelatenna",
            "Weragala",
            "Yatagama",
            "Yatapana",
            "Yatiyantota",
            "Yattogoda"
        ]
    },
    "Southern": {
        "Galle": [
            "Agaliya",
            "Ahangama",
            "Ahungalla",
            "Akmeemana",
            "Aluthwala",
            "Ambalangoda",
            "Ampegama",
            "Amugoda",
            "Anangoda",
            "Angulugaha",
            "Ankokkawala",
            "Baddegama",
            "Balapitiya",
            "Banagala",
            "Batapola",
            "Bentota",
            "Boossa",
            "Dikkumbura",
            "Dodanduwa",
            "Ella Tanabaddegama",
            "Elpitiya",
            "Ethkandura",
            "Galle",
            "Ganegoda",
            "Ginimellagaha",
            "Gintota",
            "Godahena",
            "Gonagalpura",
            "Gonamulla Junction",
            "Gonapinuwala",
            "Habaraduwa",
            "Haburugala",
            "Halvitigala Colony",
            "Hawpe",
            "Hikkaduwa",
            "Hiniduma",
            "Hiyare",
            "Ihala Walpola",
            "lhalahewessa",
            "lmaduwa",
            "lnduruwa",
            "Kahaduwa",
            "Kahawa",
            "Kananke Bazaar",
            "Karagoda",
            "Karandeniya",
            "Kosgoda",
            "Kottawagama",
            "Kuleegoda",
            "Magedara",
            "Malgalla Talangalla",
            "Mapalagama Central",
            "Mapalagama",
            "Mattaka",
            "Meda-Keembiya",
            "Meetiyagoda",
            "Miriswatta",
            "Nagoda",
            "Nakiyadeniya",
            "Nawadagala",
            "Neluwa",
            "Nindana",
            "Opatha",
            "Panangala",
            "Pannimulla Panagoda",
            "Parana ThanaYamgoda",
            "Pitigala",
            "Poddala",
            "Porawagama",
            "Rantotuwila",
            "Ratgama",
            "Talagampola",
            "Talgaspe",
            "Talgaswela",
            "Talpe",
            "Tawalama",
            "Tiranagama",
            "Udalamatta",
            "Udugama",
            "Uluvitike",
            "Unawatuna",
            "Unenwitiya",
            "Uragaha",
            "Uragasmanhandiya",
            "Wakwella",
            "Walahanduwa",
            "Wanchawela",
            "Wanduramba",
            "Warukandeniya",
            "Watugedara",
            "Weihena",
            "Yakkalamulla",
            "Yatalamatta"
        ],
        "Matara": [
            "Akuressa",
            "Alapaladeniya",
            "Aparekka",
            "Athuraliya",
            "Bengamuwa",
            "Beralapanathara",
            "Bopagoda",
            "Dampahala",
            "Deegala Lenama",
            "Deiyandara",
            "Dellawa",
            "Denagama",
            "Denipitiya",
            "Deniyaya",
            "Derangala",
            "Devinuwara (Dondra)",
            "Dikwella",
            "Diyagaha",
            "Diyalape",
            "Gandara",
            "Godapitiya",
            "Gomilamawarala",
            "Hakmana",
            "Handugala",
            "Horapawita",
            "Kalubowitiyana",
            "Kamburugamuwa",
            "Kamburupitiya",
            "Karagoda Uyangoda",
            "Karaputugala",
            "Karatota",
            "Kekanadurra",
            "Kiriweldola",
            "Kiriwelkele",
            "Kolawenigama",
            "Kotapola",
            "Kottegoda",
            "Lankagama",
            "Makandura",
            "Maliduwa",
            "Maramba",
            "Matara",
            "Mediripitiya",
            "Miella",
            "Mirissa",
            "Moragala Kirillapone",
            "Morawaka",
            "Mulatiyana Junction",
            "Nadugala",
            "Naimana",
            "Narawelpita",
            "Pahala Millawa",
            "Palatuwa",
            "Paragala",
            "Parapamulla",
            "Pasgoda",
            "Penetiyana",
            "Pitabeddara",
            "Pothdeniya",
            "Puhulwella",
            "Radawela",
            "Ransegoda",
            "Ratmale",
            "Rotumba",
            "Siyambalagoda",
            "Sultanagoda",
            "Telijjawila",
            "Thihagoda",
            "Urubokka",
            "Urugamuwa",
            "Urumutta",
            "Viharahena",
            "Walakanda",
            "Walasgala",
            "Waralla",
            "Weligama",
            "Wilpita",
            "Yatiyana"
        ],
        "Hambantota": [
            "Ambalantota",
            "Angunakolapelessa",
            "Bandagiriya Colony",
            "Barawakumbuka",
            "Beliatta",
            "Beragama",
            "Beralihela",
            "Bowalagama",
            "Bundala",
            "Ellagala",
            "Gangulandeniya",
            "Getamanna",
            "Goda Koggalla",
            "Gonagamuwa Uduwila",
            "Gonnoruwa",
            "Hakuruwela",
            "Hambantota",
            "Horewelagoda",
            "Hungama",
            "Ihala Beligalla",
            "Ittademaliya",
            "Julampitiya",
            "Kahandamodara",
            "Kariyamaditta",
            "Katuwana",
            "Kawantissapura",
            "Kirama",
            "Kirinda",
            "Lunama",
            "Lunugamwehera",
            "Magama",
            "Mahagalwewa",
            "Mamadala",
            "Medamulana",
            "Middeniya",
            "Migahajandur",
            "Modarawana",
            "Mulkirigala",
            "Nakulugamuwa",
            "Netolpitiya",
            "Nihiluwa",
            "Padawkema",
            "Pahala Andarawewa",
            "Pallekanda",
            "Rammalawarapitiya",
            "Ranakeliya",
            "Ranmuduwewa",
            "Ranna",
            "Ratmalwala",
            "RU/Ridiyagama",
            "Sooriyawewa Town",
            "Tangalla",
            "Tissamaharama",
            "Uda Gomadiya",
            "Udamattala",
            "Uswewa",
            "Vitharandeniya",
            "Walasmulla",
            "Weeraketiya",
            "Weerawila NewTown",
            "Weerawila",
            "Wekandawela",
            "Weligatta",
            "Yatigala"
        ]
    },
    "Uva": {
        "Badulla": [
            "Akkarasiyaya",
            "Aluketiyawa",
            "Aluttaramma",
            "Ambadandegama",
            "Ambagahawatta",
            "Ambagasdowa",
            "Amunumulla",
            "Arawa",
            "Arawakumbura",
            "Arawatta",
            "Atakiriya",
            "Badulla",
            "Baduluoya",
            "Ballaketuwa",
            "Bambarapana",
            "Bandarawela",
            "Beramada",
            "Bibilegama",
            "Bogahakumbura",
            "Boragas",
            "Boralanda",
            "Bowela",
            "Dambana",
            "Demodara",
            "Diganatenna",
            "Dikkapitiya",
            "Dimbulana",
            "Divulapelessa",
            "Diyatalawa",
            "Dulgolla",
            "Egodawela",
            "Ella",
            "Ettampitiya",
            "Galauda",
            "Galedanda",
            "Galporuyaya",
            "Gamewela",
            "Gawarawela",
            "Girandurukotte",
            "Godunna",
            "Gurutalawa",
            "Haldummulla",
            "Hali Ela",
            "Hangunnawa",
            "Haputale",
            "Hebarawa",
            "Heeloya",
            "Helahalpe",
            "Helapupula",
            "Hewanakumbura",
            "Hingurukaduwa",
            "Hopton",
            "Idalgashinna",
            "Jangulla",
            "Kahataruppa",
            "Kalubululanda",
            "Kalugahakandura",
            "Kalupahana",
            "Kandaketya",
            "Kandegedara",
            "Kandepuhulpola",
            "Kebillawela",
            "Kendagolla",
            "Keppetipola",
            "Keselpotha",
            "Ketawatta",
            "Kiriwanagama",
            "Koslanda",
            "Kotamuduna",
            "Kuruwitenna",
            "Kuttiyagolla",
            "Landewela",
            "Liyangahawela",
            "Lunugala",
            "Lunuwatta",
            "Madulsima",
            "Mahiyanganaya",
            "Makulella",
            "Malgoda",
            "Maliyadda",
            "Mapakadawewa",
            "Maspanna",
            "Maussagolla",
            "Medawela Udukinda",
            "Medawelagama",
            "Meegahakiula",
            "Metigahatenna",
            "Mirahawatta",
            "Miriyabedda",
            "Miyanakandura",
            "Namunukula",
            "Narangala",
            "Nelumgama",
            "Nikapotha",
            "Nugatalawa",
            "Ohiya",
            "Pahalarathkinda",
            "Pallekiruwa",
            "Passara",
            "Pathanewatta",
            "Pattiyagedara",
            "Pelagahatenna",
            "Perawella",
            "Pitamaruwa",
            "Pitapola",
            "Puhulpola",
            "Ratkarawwa",
            "Ridimaliyadda",
            "Rilpola",
            "Silmiyapura",
            "Sirimalgoda",
            "Sorabora Colony",
            "Soragune",
            "Soranatota",
            "Spring Valley",
            "Taldena",
            "Tennepanguwa",
            "Timbirigaspitiya",
            "Uduhawara",
            "Uraniya",
            "Uva Deegalla",
            "Uva Karandagolla",
            "Uva Mawelagama",
            "Uva Tenna",
            "Uva Tissapura",
            "Uva Uduwara",
            "Uvaparanagama",
            "Welimada",
            "Wewatta",
            "Wineethagama",
            "Yalagamuwa",
            "Yalwela"
        ],
        "Monaragala": [
            "Angunakolawewa",
            "Ayiwela",
            "Badalkumbura",
            "Baduluwela",
            "Bakinigahawela",
            "Balaharuwa",
            "Bibile",
            "Buddama",
            "Buttala",
            "Dambagalla",
            "Diyakobala",
            "Dombagahawela",
            "Ekamutugama",
            "Ekiriyankumbura",
            "Ethimalewewa",
            "Ettiliwewa",
            "Galabedda",
            "Hambegamuwa",
            "Hulandawa",
            "Inginiyagala",
            "Kandaudapanguwa",
            "Kandawinna",
            "Kataragama",
            "Kiriibbanwewa",
            "Kotagama",
            "Kotawehera Mankada",
            "Kotiyagala",
            "Kumbukkana",
            "Mahagama Colony",
            "Marawa",
            "Mariarawa",
            "Medagana",
            "Monaragala",
            "Moretuwegama",
            "Nakkala",
            "Nannapurawa",
            "Nelliyadda",
            "Nilgala",
            "Obbegoda",
            "Okkampitiya",
            "Pangura",
            "Pitakumbura",
            "Randeniya",
            "Ruwalwela",
            "Sella Kataragama",
            "Sewanagala",
            "Siyambalagune",
            "Siyambalanduwa",
            "Suriara",
            "Tanamalwila",
            "Uva Gangodagama",
            "Uva Kudaoya",
            "Uva Pelwatta",
            "Warunagama",
            "Wedikumbura",
            "Weherayaya Handapanagala",
            "Wellawaya",
            "Wilaoya"
        ]
    }
}

import { useCityStore } from "@/stores/cityStore";
import axios from "axios";
import { URLService } from "./_url";

let cityListWithProvinces = [];
export const CityService = {
    async getallcities() {
        cityListWithProvinces = [];
        for (let province in cities) {
            for (let district in cities[province]) {
              cities[province][district].forEach((city) => {
                cityListWithProvinces.push({ city: city, province: province, district: district });
              });
            }
          }
        return cityListWithProvinces;
    },

    async storeCities() {
        const cityStore = useCityStore();
        try {
            let citylist = [];
            let url = URLService.getcitylist;
            let response = await axios.get(url);
            if(response.status == 200){
                citylist = response.data.data;
            }
            cityStore.storeCities(citylist);            
        } catch (error) {
            cityStore.storeCities([]);
        }

    },
    async getCities() {
        const cityStore = useCityStore();
        if(cityStore.getCities() == null || cityStore.getCities().length == 0){
            await this.storeCities();
        }
        return await cityStore.getCities();
    }
}


